import React from 'react';
import "./inicio.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export default function Inicio(props) {
    return (
        <React.Fragment>
            <div className='row'>
                <h2 className='mb-5'>Módulos</h2>
                <div className='col'>
                    <Link to={'/desarme'}>
                        <Card sx={{ maxWidth: 270, minHeight:270, position:'relative', backgroundColor:'#b3e5fc'}}>
                            <CardContent sx={{position:'absolute',top: '1.5rem'}}>
                                <Typography gutterBottom variant="h5" component="div">
                                 Desarme
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Ingresar piezas, gestionar y revisar acciones como ventas y devoluciones.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </div>
                <div className='col'>
                    <Link to={'/ordenescompra'}>
                        <Card sx={{ maxWidth: 270, minHeight:270, position:'relative', backgroundColor:'#b3e5fc'}}>
                            <CardContent sx={{position:'absolute',top: '1.5rem'}}>
                                <Typography gutterBottom variant="h5" component="div">
                                 Ordenes de Compra
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Ingresar OC, gestionarlas y hacer seguimiento. Permite ingreso de archvio Invoice 
                                    para actualizar estado de compras y stock.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </div>
                <div className='col'>
                    <Link to={'/stock'}>
                        <Card sx={{ maxWidth: 270, minHeight:270, position:'relative', backgroundColor:'#b3e5fc'}}>
                            <CardContent sx={{position:'absolute',top: '1.5rem'}}>
                                <Typography gutterBottom variant="h5" component="div">
                                 Stock AUTOFIX
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Ver y gestionar stock.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </div>
                <div className='col'>
                    <Link to={'/stock24lemans'}>
                        <Card sx={{ maxWidth: 270, minHeight:270, position:'relative', backgroundColor:'#b3e5fc'}}>
                            <CardContent sx={{position:'absolute',top: '1.5rem'}}>
                                <Typography gutterBottom variant="h5" component="div">
                                    Stock 24LEMANS
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Ver y gestionar stock.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </div>
                <div className='col'>
                    <Link to={'/stockImports'}>
                        <Card sx={{ maxWidth: 270, minHeight:270, position:'relative', backgroundColor:'#b3e5fc'}}>
                            <CardContent sx={{position:'absolute',top: '1.5rem'}}>
                                <Typography gutterBottom variant="h5" component="div">
                                    Stock IMPORTACIONES
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Ver y gestionar stock.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}

//API HOST
require('dotenv').config();

// NUEVA API PYTHON
export const API_HOST_PY = process.env.REACT_APP_API_HOST_PY;

//API ANTERIOR
export const API_HOST = process.env.REACT_APP_API_HOST;


//Compras
export const URL_LOGIN = API_HOST + '/login';
export const URL_FETCH_STOCK = API_HOST + '/stock';

export const URL_FETCH_COMPRAS = API_HOST + '/compras';
export const URL_FETCH_ULTIMAS_COMPRAS = API_HOST + '/ultactcompras';
export const URL_FETCH_ULTIMAS_COMPRAS_INGRESADAS = API_HOST + '/ultComprasIng';
export const URL_FETCH_SOLIC_SIN_MATCH = API_HOST + '/solicsinmatch';

export const URL_UPDATE_COMPRA = API_HOST + '/actCompra';
export const URL_UPDATE_COSTOS = API_HOST + '/actCostoImp';

export const URL_FETCH_PROVEEDORES = API_HOST + '/proveedores';

export const URL_ELIMINAR_COMPRA = API_HOST + '/eliminarCompra';
export const URL_EDITAR_COMPRA = API_HOST + '/editarCompra';
export const URL_VINCULAR_OC = API_HOST + '/vincularOC';

export const URL_FETCH_ACT_COMPRAS = API_HOST + '/actCompras';
export const URL_FETCH_PEDIDOS_DUMP = API_HOST + '/pedidosDump';

export const URL_DESHACER_INVOICE = API_HOST + '/deshacerInvoice';

//Desarme
export const URL_FETCH_PIEZAS_DESARME = API_HOST + '/piezasdesarme';
export const URL_FETCH_ACCIONES_DESARME = API_HOST + '/accionesdesarme';
export const URL_FETCH_ULT_PIEZAS_DESARME = API_HOST + '/ultpdesarme';
export const URL_INSERT_PIEZAS_DESARME = API_HOST + '/ingpiezasdesarme';

export const URL_CONFIRMAR_INRESO = API_HOST + '/confirmarIng';
export const URL_CANCELAR_INGRESO = API_HOST + '/cancelarIng';

//Piezas
export const URL_EDIT_PIEZA =  API_HOST + '/editarpieza';
export const URL_DELETE_PIEZA = API_HOST + '/desarme/delete';
export const URL_ACCION_PIEZA = API_HOST + '/accionPiezaDesarme';

//Post
export const URL_FETCH_ULTIMAS_MODIFICACIONES = API_HOST + '/ultmodificaciones';


//Invoice
export const URL_FETCH_INGVOID = API_HOST + '/confirmarInvoice';
export const URL_FETCH_PREINGVOID = API_HOST + '/ingInvoice';
export const URL_FETCH_PREPAREUPDATE = API_HOST + '/prepareUpdate';
export const URL_POST_PRODUCTOS_SIN_OC = API_HOST+ '/IngProducto';
//OC
export const URL_INSERT_OC = API_HOST + '/ingordenes';
export const URL_INSERT_ARCHIVO_OC = API_HOST + '/ingArchivoOC';
export const URL_DESCRIPCIONES_OC = API_HOST + '/descripciones';
export const URL_LISTA_CODIGOS = API_HOST + '/listacodigos';

export const URL_INSERT_PRODUCTOS_STOCK = API_HOST + '/IngProducto';

//Stock
export const URL_ING_STOCK = API_HOST + '/ingStock'; //Verificar
export const URL_FETCH_BODEGAS = API_HOST + '/stock/bodegas';
export const URL_FETCH_RACKS_BY_ID_BODEGA = API_HOST + '/stock/racks';
export const URL_FETCH_FILAS_BY_ID_RACK = API_HOST + '/stock/filas';
export const URL_FETCH_CAJAS_BY_ID_FILA = API_HOST + '/stock/cajas';
export const URL_PUT_COMPRA_STOCK_BY_ID_CAJA = API_HOST + '/stock/addToStock';
export const URL_POST_ARCHIVO_STOCK = API_HOST + '/stock/archivoStock';
export const URL_POST_EDITAR_STOCK = API_HOST + '/stock/editarStock';
export const URL_POST_UBICACIONES = API_HOST + '/stock/postUbicaciones';

//Authentication and Roles Permissions
//5 - Admin
//6...10 Logica de negocio
//////////

var RolesEnum = {
  bodeguero: 1,
  contentManager: 2,
  vendedor: 3,
  admin: 5,
  bloger: 6,
};

const getPermisosByToken = (token) => {
  return token.data[0].permisos;
};

export const getUserByToken = (token) => {
  return token.data[0].nombre;
};

//Lógica de negocio funciones de Permisos
export const isOnlyBodeguero = (token) => {
  return getPermisosByToken(token) === RolesEnum.bodeguero;
};

export const isBloger = (token) => {
  return getPermisosByToken(token) === RolesEnum.bloger;
};

//Roles son Jerarquias de Permisos
export const hasRoleBodeguero = (token) => {
  return getPermisosByToken(token) >= RolesEnum.bodeguero;
};

export const hasRoleContentManager = (token) => {
  return getPermisosByToken(token) >= RolesEnum.contentManager;
};

export const hasRoleVendedor = (token) => {
  return getPermisosByToken(token) >= RolesEnum.vendedor;
};

export const hasRoleAdmin = (token) => {
  return getPermisosByToken(token) >= RolesEnum.admin;
};

import React, { useEffect,useRef } from "react";
import { connect } from "react-redux";
import actions from "../../../actions";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ModalExito from "../../Modales/ModalExito";
import ModalError from "../../Modales/ModalError";
import IngresoOC from "../IngresoOC";

function ModalIngresoOC({
  showModal,titulo,mensaje,token
}) {

  /*LOGICA DE MODAL */
  /* Activadores modales de exito o error */
  const showModalExito = useRef(null);
  const showModalError = useRef(null);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    showModal.current = handleClickOpen
  }, [showModal])  
  /****/

  /*click ingresar */
  const ingresarOC = useRef(null)

  let ingresarOrdenes = () =>{
    let resp = ingresarOC.current();
    if(resp){
      showModalExito.current();
    }else{
      showModalError.current();
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title">
          {titulo}
        </DialogTitle>
        <DialogContent>
          <IngresoOC token={token} ingresarOC={ingresarOC} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={() => ingresarOrdenes()} autoFocus>
            Ingresar
          </Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODALES */}
      <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Ingreso de producto exitoso!'} />
      <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error al ingresar producto a stock!'} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  addProductToStock: state.addProductToStock,
});

const mapDispatchToProps = {
  dispatchAddProductToStock: actions.postProductoStock,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalIngresoOC);
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { URL_POST_EDITAR_STOCK } from "../../../config";
import ModalExito from "../../Modales/ModalExito";
import ModalError from "../../Modales/ModalError";

export default function ModalEditarStock({showModal,titulo,mensaje, token}) {
  let [stockEditar,setStockEditar] = useState({});
  let [stockOrig] = useState({});

  /*logica de modal */
  const [open, setOpen] = useState(false);

  const handleClickOpen = (stock) => {
    if(stock){
      setStockEditar(stock);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    showModal.current = handleClickOpen;
  }, [showModal])  
/** **/

  let onEditarStockClick = async() => {
    await axios.post(URL_POST_EDITAR_STOCK,
        {stockEditar,token,stockOrig})
        .then((resp)=>{
          if(resp.status===200){
              showModalExito.current();
              handleClose();
          }
      })
      .catch((error)=>{
          showModalError.current();
          handleClose();
      });
        handleClose();
  }

  //activador modal exito
  const showModalExito = useRef(null);
  const showModalError = useRef(null);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth='xl'
      >
        <DialogTitle id="alert-dialog-title">
          {titulo}
        </DialogTitle>
        <DialogContent>
        <div className='table-responsive'>
                    <table className="table table-striped table-sm">
                        <thead className='table-dark'>
                            <tr>
                                <th scope="col">codigo_acc_stck</th>
                                <th scope="col">Descripcion</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Cantidad Almacenada</th>
                                <th scope="col">Bodega</th>
                                <th scope="col">Rack</th>
                                <th scope="col">Fila</th>
                                <th scope="col">Caja</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td><input disabled defaultValue={stockEditar.codigo_acc_stck} onChange= {(event) => {stockEditar.codigo_acc_stck=(event.target.value)}}/></td>
                            <td>
                                <input defaultValue={stockEditar.descripcion} onChange= {(event) => {stockEditar.descripcion=(event.target.value)}}/>
                            </td>
                            <td><input defaultValue={stockEditar.sku_asoc} onChange= {(event) => {stockEditar.sku_asoc=(event.target.value)}}/></td>
                            <td><input defaultValue={stockEditar.cantidad_almacenada} onChange= {(event) => {stockEditar.cantidad_almacenada=(event.target.value)}}/></td>
                            <td><input defaultValue={stockEditar.bodega} onChange= {(event) => {stockEditar.bodega=(event.target.value)}}/></td>
                            <td><input defaultValue={stockEditar.rack} onChange= {(event) => {stockEditar.rack=(event.target.value)}}/></td>
                            <td><input defaultValue={stockEditar.fila} onChange= {(event) => {stockEditar.fila=(event.target.value)}}/></td>
                            <td><input defaultValue={stockEditar.caja} onChange= {(event) => {stockEditar.caja=(event.target.value)}}/></td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={onEditarStockClick} autoFocus>
            Editar
          </Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODALES */}
      <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Ingreso de productos exitoso!'} />
      <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error al subir archivo de productos a stock!'} />
    </div>
  );
}
import React, { useState, useEffect } from "react";
import actions from "../../../actions";
import { connect } from "react-redux";
/*mui */
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress } from "@mui/material";

/*data grid */
const columns = [
  {
    field: 'codigo_invoice',
    headerName: 'Codigo Invoice',
    width: 150,
    editable: true,
  },
  {
    field: 'codigo_dess',
    headerName: 'Codigo DESS',
    width: 150,
    editable: true,
  },
  {
    field: 'num_parte',
    headerName: 'Num Parte',
    width: 110,
    editable: true,
  },
  {
    field: 'descripcion',
    headerName: 'Descripcion',
    width: 350,
    sortable:true
  },
  {
    field: 'cantidad',
    headerName: 'Cantidad Recibida',
    sortable: false,
    width: 150
  }
];

function IngresoUbicacionPaso1({
    token,
    stock,
    getSeleccion,
    lemans
  }){

  //-------- state -------//
  let [comprasStock,setComprasStock] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  let [pageSize,setPageSize] = useState(10);
  /**funciones */
  let getSeleccionInv = ()=>{
    return(selectionModel);
  }
  /**effects */
  useEffect(() => {
    getSeleccion.current = getSeleccionInv
  }, [selectionModel])

  useEffect(() => {
    let comprasAux = stock.data.filter(filtrarComprasStock)
    setComprasStock(comprasAux);
  }, [stock])

  function filtrarComprasStock(obj) {
    if(lemans){
      if(obj.tienda !== '24LEMANS'){
        return false;
      }else{
        if (!obj.bodega) {
          return true
        } else {
          return false;
        }
      }
    } else {
      if(obj.tienda !== 'AUTOFIX'){
        return false;
      }else{
        if (!obj.bodega) {
          return true;
        } else {
          console.log("error estado",obj.estado)
          return false;
        }
      }
    }
  }

  return (
    <React.Fragment>
        <hr/>
        {stock.loading && <LinearProgress color="success" />}
        <div style={{ height: 550, width: '100%' }}>
          <DataGrid
            rows={comprasStock}
            columns={columns}
            getRowId={(row)=>row.codigo}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10,20,50]}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
          />
        </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  stock: state.stock,
});

const mapDispatchToProps = {
  dispatchFetchStock: actions.fetchStock
};

export default connect(mapStateToProps, mapDispatchToProps)(IngresoUbicacionPaso1);







//ticketDto
export default class TicketDto {
    constructor() {
        this.codigo = 0;
        this.tipo_id = '';
        this.titulo = '';
        this.autor = '';
        this.fecha_creacion = '';
        this.fecha_modificacion = '';
        this.estado = 0;
        this.pagado = false;
        this.texto = '';
        this.tipo_id= 0;
    }
}
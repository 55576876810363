import React, { useState, useEffect,useRef } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import ModalExito from "../../Modales/ModalExito";
import ModalError from "../../Modales/ModalError";
import {
  URL_FETCH_PREINGVOID,URL_FETCH_INGVOID
} from "../../../config";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";


const steps = ['Pre-vizualizar archivo', 'Pre-vizualizar resultados', 'Confirmacion'];

export default function ModalArchivoInvoice({showModal,data,columns,file,token,setLoader,tiendaSelec}) {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    return step === 5;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if(activeStep===0){
      preCargarInvoice();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }else if(activeStep===1){
      confirmarInvoice();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }else if(activeStep===2){
      handleClose();
      setActiveStep(0);
    }
    setSkipped(newSkipped);
  };
 
  const handleReset = () => {
    setActiveStep(0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

    //-------- variables de archivo excel --------//  
    const handleSubmission = async() => { 
      var formData = new FormData();
      formData.append("image", file);
      formData.append("token", token.data[0].nombre);
      formData.append("tienda",tiendaSelec);
      var response = await axios.post(URL_FETCH_PREINGVOID, formData, { 
          headers: {
          'Content-Type': 'multipart/form-data'
          }
      }).then((resp)=>{
          if(resp.status===200){
              showModalExito.current();
              setLoader(data.length);
              handleClose();
          }
      })
      .catch((error)=>{
          showModalError.current();
          setLoader();
          handleClose();
      });
      console.log(response);
    };

    const preCargarInvoice = async() => { 
      var formData = new FormData();
      formData.append("image", file);
      formData.append("token",token.data[0].nombre);
      formData.append("tienda",tiendaSelec);
      var response = await axios.post(URL_FETCH_PREINGVOID, formData, { 
          headers: {
          'Content-Type': 'multipart/form-data'
          }
      }).then((resp)=>{
          if(resp.status===200){
              return true;

          }
      })
      .catch((error)=>{
          return false;
      });
      console.log(response);
    };

    const confirmarInvoice = async() => { 
      var response = await axios.get(URL_FETCH_INGVOID, { 
          headers: {
          'Content-Type': 'multipart/form-data'
          }
      }).then((resp)=>{
          if(resp.status===200){
              return true;
          }
      })
      .catch((error)=>{
          return false;
      });
      console.log(response);
    };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setActiveStep(0);
    setOpen(false);
  };

  useEffect(() => {
    showModal.current = handleClickOpen;
    setActiveStep(0);
  }, [])  

//activador modal exito
  const showModalExito = useRef(null);
  const showModalError = useRef(null);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth='xl'
      >
        <DialogTitle id="alert-dialog-title">
          {"Pre-visualizacion de Invoice a subir"}
        </DialogTitle>
        <DialogContent>
          <TableContainer>
          <Table>
            <TableHead>
              {data?.map((elem,index)=>{
                    if(index ===0){
                      return(
                        <TableRow key={index}>
                            <TableCell>{elem[0]}</TableCell>
                            <TableCell>{elem[1]}</TableCell>
                            <TableCell>{elem[2]}</TableCell>
                            <TableCell>{elem[3]}</TableCell>
                            <TableCell>{elem[4]}</TableCell>
                            <TableCell>{elem[5]}</TableCell>
                            <TableCell>{elem[6]}</TableCell>
                            <TableCell>{elem[7]}</TableCell>
                        </TableRow>
                      )
                    }else void 0;
      
                })}
              </TableHead>
              <TableBody>
                {data?.map((elem,index)=>{
                      if(index !=0){
                        return(
                          <TableRow key={index}>
                              <TableCell>{elem[0]}</TableCell>
                              <TableCell>{elem[1]}</TableCell>
                              <TableCell>{elem[2]}</TableCell>
                              <TableCell>{elem[3]}</TableCell>
                              <TableCell>{elem[4]}</TableCell>
                              <TableCell>{elem[5]}</TableCell>
                              <TableCell>{elem[6]}</TableCell>
                              <TableCell>{elem[7]}</TableCell>
                          </TableRow>
                        )
                      }else void 0;
        
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={handleSubmission}>
            Confirmar
          </Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODALES */}
        <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Ingreso de productos exitoso!'} />
        <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error al subir archivo de productos a stock! Revisar los campos vacios en archivo excel'} />
    </div>
  );
}
import React, { useState,useEffect,useRef} from 'react'
import axios from "axios";
import { connect } from "react-redux";
import actions from "../../actions";
import {ExcelRenderer} from 'react-excel-renderer';
import ReactToPrint from 'react-to-print';
import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCheckbox,
    MDBIcon,
    MDBSwitch
  } from 'mdb-react-ui-kit';

import {
    URL_INSERT_OC,
    URL_INSERT_ARCHIVO_OC,
    URL_DESCRIPCIONES_OC,
    URL_LISTA_CODIGOS,
} from "../../config";
import ModalArchivoOC from './Modales/ModalArchivoOC';
import ModalExito from '../Modales/ModalExito';
import ModalError from '../Modales/ModalError';
import {hasRoleAdmin} from "../../core/auth/auth";

function IngresoOC({token,proveedores,dispatchProveedores, ingresarOC}) {
    //-------- variable ref para imprimir --------//
    const tablaActRef = useRef();
    //-------- state --------//
    let [fechaHoy,setFechaHoy] = useState('');
    let [divisaOC,setDivisaOC] = useState('USD')
    let [fechaOC,setFechaOC] = useState('');
    let [numParteProvHom,setNumParteProvHom] = useState('');
    let [codigoDessOC,setCodigoDessOC] = useState('');
    let [numParteProv,setnumParteProv] = useState('');
    let [descripcionOC,setDescripcionOC] = useState('');
    let [cantidadOC,setCantidadOC] = useState(0);
    let [costoUnitOC,setCostoUnitOC] = useState(0);
    let [costoEnvioOC,setCostoEnvioOC] = useState(0);
    let [costoTotalOC,setCostoTotalOC] = useState(0);
    let [proveedorOC,setProveedorOC] = useState('');
    let [numOC,setNumOC] = useState('');
    let [tipoOC,setTipoOC] = useState(hasRoleAdmin(token)? 'OC': 'IM');
    let [estadoOC,setEstadoOC] = useState('PENDIENTE');
    let [fechaEstLleg,setFechaEstLLeg] = useState('');
    let [nombreVendedorOC,setNombreVendedorOC] = useState('');
    let [ordenes,setOrdenes] = useState([]);
    let [descripcionesForm,setDescripcionesForm] = useState([]);
    let [lista_codigos_sku,setlista] = useState([]);
    let [teleCliente,setTeleCliente] = useState('');
    let [notaVenta,setNotaVenta] = useState('');
    //----------- paginacion de tabla ----------------//

    //------- effects -------//

    useEffect(() => {
        ingresarOC.current = onClickSubmit
    }, [ordenes])

    useEffect(() =>{
        dispatchProveedores()
    },[dispatchProveedores])

    useEffect(() =>{
        let fecha_hoy = Date.now();
        let fecha_hoy_obj = new Date(fecha_hoy);
        let prov = proveedores.data.find(prov => prov.nombre_prov === proveedorOC)
        let fecha_estimada = addDaysToDate(fecha_hoy_obj,prov?prov.dias_de_entrega: 0 );
        let fecha_estimada_obj = new Date(fecha_estimada)
        setFechaEstLLeg(fecha_estimada_obj.toLocaleDateString('en-GB'));
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.id==='fechaEstInput'? input.value = fecha_estimada_obj.toLocaleDateString('en-GB'): void 0)
        );
        
    },[proveedorOC])

    useEffect(() =>{
        let sumaCosto = (costoUnitOC*cantidadOC)+(parseInt(costoEnvioOC)*cantidadOC)
        setCostoTotalOC(sumaCosto)
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.id==='costototalinput'? input.value = sumaCosto: void 0)
          );
    },[costoUnitOC,costoEnvioOC])

    useEffect(() =>{
        if(tipoOC==='IM'){
            setCostoUnitOC()
            setCostoEnvioOC()
            setCostoTotalOC()
        }
    },[tipoOC])

    useEffect(() => {
        (async () => {
            //get Carrocerias
            var response = await axios.post(URL_DESCRIPCIONES_OC,
            {codigoDessOC});
            //let responseBody = await response.json();
            //console.log(response.data)
            setDescripcionesForm(response.data);  
        })();
    }, [codigoDessOC]);

    useEffect(() => {
        (async () => {
            //get Carrocerias
            var response = await axios.post(URL_LISTA_CODIGOS,
            {codigoDessOC});
            //let responseBody = await response.json();
            //console.log(response.data)
            setlista(response.data);  
        })();
    }, [codigoDessOC]);

    //-------- funciones, eventos de click -------//
    function addDaysToDate(date, days){
        var res = new Date(date);
        res.setDate(res.getDate() + days);
        return res;
    }

    let validarCamposOC = ()=>{
        if(tipoOC ==='IM'){
            if( fechaOC==='' || descripcionOC==='' || cantidadOC==='' || numOC==='' 
            || tipoOC ==='' || codigoDessOC==='' 
            || proveedorOC ==='' || fechaEstLleg==='') {

                return false
            }else{ return true}
        }else{
            if( fechaOC==='' || descripcionOC==='' || cantidadOC==='' || numOC==='' 
            || tipoOC ==='' || codigoDessOC==='' || costoUnitOC==='' || costoEnvioOC==='' 
            || costoTotalOC==='' || proveedorOC ==='' || fechaEstLleg==='' || nombreVendedorOC==='') 
            {
                return false
            }else{
                return true
            }
        }

    };

    let onClickAgregarOC = ()=>{
        if(validarCamposOC()){
            var ordenCompra = {
                fechaoc: fechaOC.split('-').reverse().join('/'),
                tipo: tipoOC,
                codigo_dess:codigoDessOC,
                descripcion: descripcionOC,
                cantidad: cantidadOC,
                costo_unitario: costoUnitOC,
                costo_total: costoTotalOC,
                costo_envio: costoEnvioOC,
                proveedor: proveedorOC,
                num_oc: numOC,
                estado: estadoOC,
                fecha_estimada_llegada: fechaEstLleg,
                divisa: divisaOC,
                num_parte_prov: numParteProv,
                num_parte_prov_h:numParteProvHom,
                nombre_vendedor:nombreVendedorOC,
                tele_cliente:teleCliente,
                nota_venta:notaVenta
            };            
            let newArray = [...ordenes, ordenCompra ];
            setOrdenes(newArray);
            setDescripcionOC('');
            setCantidadOC('');
            setnumParteProv('');
            setCostoTotalOC('');
            setCostoUnitOC('');
            setCostoEnvioOC('');
            setProveedorOC('');
            setNumOC('');
            setTipoOC('OC');
            //setFechaEstLLeg('');
            setNumParteProvHom('');
            setNombreVendedorOC('');
            setTeleCliente('');
            setNotaVenta('');
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.id==='fechaOC'?void 0 :input.value = "")
              );
        }
    };

    let onClickQuitarOC = (orden) => {
        let nuevoArray = ordenes.filter(elem => 
            elem.codigo != orden.codigo
        );    
        setOrdenes(nuevoArray)
    };

    let onClickSubmit = () => {
        console.log("agrgando OC",ordenes);
        return new Promise((resolve, reject) => {
             axios.post(URL_INSERT_OC,
                {ordenes: ordenes, token: token.data})
                .then((resp)=>{
                    if(resp.status===200){
                        setOrdenes([]);
                        //showModalExito.current();
                        resolve(true);
                    }
                })
                .catch((error)=>{
                    //showModalError.current();
                    reject(false)
                });
        });        
    }

    //activadores modal
    const showModalExito = useRef(null);
    const showModalError = useRef(null);
    
    return (
        <React.Fragment>
            <form>
            <div className='container'>
                <div className='row row-cols-5 mb-3'>
                    <div className='col'>
                        <label className="form-label">Fecha Compra</label>
                        <input type="date" id='fechaOC' className="form-control" onChange={(event)=>setFechaOC(event.target.value)} required
                        />
                    </div>
                    <div className='col'>
                        <label  className="form-label">Numero Orden</label>
                        <input type="text" className="form-control" onChange={(event)=>setNumOC(event.target.value.toUpperCase())} required/>
                    </div>
                    <div className='col'>
                        <label  className="form-label">Numero Parte Proveedor</label>
                        <input type="text" className="form-control" onChange={(event)=>setnumParteProv(event.target.value)} required />
                    </div>
                    <div className='col'>
                        <label  className="form-label">Codigo DESS</label>
                        <input type="search" list="datalistOptions2" className="form-control" id="floatingInput"
                            onChange={(event)=> {setCodigoDessOC(event.target.value.toUpperCase())}}
                        />
                        <datalist id="datalistOptions2">
                            {/*familias start */}
                            {lista_codigos_sku.map( (des,index)=> 
                                <option key={index}>{des.sku}</option>
                            )}
                            {/*familias end */}
                        </datalist>                    
                    </div>
                    <div className='col'>
                        <label  className="form-label">Descripcion</label>
                        <input type="search" list="datalistOptions" className="form-control" id="floatingInput" 
                            onChange= {(event) => {setDescripcionOC(event.target.value.toUpperCase())}}
                        />
                        <datalist id="datalistOptions">
                            {/*familias start */}
                            {descripcionesForm.map( (des,index)=> 
                                <option key={index}>{des.parte}</option>
                            )}
                            {/*familias end */}
                        </datalist>
                    </div>
                    <div className='col'>
                        <label  className="form-label">Cantidad</label>
                        <input type="number" className="form-control" onChange={(event)=>setCantidadOC(event.target.value)} required />
                    </div>
                    {tipoOC==='IM' && token.data[0].permisos != '5'? void 0 :
                        <div className='col'>
                            <label  className="form-label">Costo Unitario</label>
                            <input type="number" className="form-control" onChange={(event)=>setCostoUnitOC(event.target.value)} required />
                        </div>
                    }
                    {tipoOC==='IM' && token.data[0].permisos != '5'? void 0 :
                        <div className='col'>
                            <label  className="form-label">Costo Envio</label>
                            <input type="number" className="form-control" onChange={(event)=>setCostoEnvioOC(event.target.value)} required />
                        </div>
                    }
                    {tipoOC==='IM' && token.data[0].permisos != '5'? void 0 :
                        <div className='col'>
                            <label  className="form-label">Costo Total</label>
                            <input type="number" className="form-control" 
                                onChange={(event)=>setCostoTotalOC(event.target.value)} 
                                id='costototalinput' required 
                            />
                        </div>
                    }
                    <div className='col'>
                        <label  className="form-label">Proveedor</label>
                        <input type="search" list="datalistProveedor" className="form-control" id="floatingInput" 
                            onChange={(event)=>setProveedorOC(event.target.value.toUpperCase())} required
                        />
                        <datalist id="datalistProveedor">
                            {/*familias start */}
                            {proveedores.data.map( (prov,index)=> 
                                <option key={index}>{prov.nombre_prov}</option>
                            )}
                            {/*familias end */}
                        </datalist>
                    </div>
                    <div className='col'>
                        <label  className="form-label">Fecha est. de Llegada</label>
                        <input type="text" id='fechaEstInput'
                            defaultValue={fechaEstLleg}
                            className="form-control" onChange={(event)=>setFechaEstLLeg(event.target.value)} required readOnly/>
                    </div>
                    <div className='col'>
                        <label  className="form-label">Vendedor</label>
                        <input type="text"
                            defaultValue={nombreVendedorOC}
                            className="form-control" onChange={(event)=>setNombreVendedorOC(event.target.value)} required />
                    </div>                   
                    <div className='col' style={{position:'relative'}}>
                        <div style={{position:'absolute',top:'50%'}}>
                        <MDBSwitch id='flexSwitchCheckDefault' label='$USD' 
                            checked={divisaOC==='USD'?true:false}
                            onChange={()=> setDivisaOC('USD')}
                        />
                        <MDBSwitch id='flexSwitchCheckDefault' label='$CLP' 
                            checked={divisaOC==='CLP'?true:false}
                            onChange={()=> setDivisaOC('CLP')}
                        />
                        </div>
                    </div>
                    <div className='col' style={{position:'relative'}}>
                        <label  className="form-label text-danger">Num Parte Prov Homologo</label>
                        <input type="text" className="form-control" onChange={(event)=>setNumParteProvHom(event.target.value)} required />
                    </div>
                    <div className='col'>
                        <label  className="form-label">Teléfono Cliente</label>
                        <input type="text"
                            defaultValue={teleCliente}
                            className="form-control" onChange={(event)=>setTeleCliente(event.target.value)} required />
                    </div>
                    <div className='col'>
                        <label  className="form-label">Nota de Venta</label>
                        <input type="text"
                            defaultValue={notaVenta}
                            className="form-control" onChange={(event)=>setNotaVenta(event.target.value)} required />
                    </div>
                    <div className='col pt-4 '>
                        <MDBBtn type='submit' onClick={()=>onClickAgregarOC()} >
                            <MDBIcon fas icon="plus" /> Agregar Compra 
                        </MDBBtn>
                    </div>

                </div>
                    <MDBCheckbox name='flexCheck' value='PE' id='checkPE' label='Pedido Express' 
                        checked={tipoOC==='PE'?true:false}
                        onChange={()=> setTipoOC('PE')}
                        disabled = {!hasRoleAdmin(token)}
                    />
                    <MDBCheckbox name='flexCheck' value='OC' id='checkOC' label='Orden de Compra' 
                        checked={tipoOC==='OC'?true:false}
                        onChange={()=> setTipoOC('OC')}
                        disabled = {!hasRoleAdmin(token)}
                    />
                    <MDBCheckbox name='flexCheck' value='IM' id='checkIM' label='Importación' 
                        checked={tipoOC==='IM'?true:false}
                        onChange={()=> setTipoOC('IM')}
                    />
            </div>
            </form>
            <br/>
            <h4>INFORME DE COMPRA</h4>
            <hr/>
            <div className='table-responsive'>
            <table className="table table-striped table-sm" ref={tablaActRef}>
                <thead className='table-dark'>
                    <tr>
                        <th scope="col">Eliminar</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Num Proveedor</th>
                        <th scope="col">Codigo DESS</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripcion</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Costo Unitario</th>
                        <th scope="col">Costo Envio</th>
                        <th scope="col">Costo Total</th>
                        <th scope="col">Divisa</th>
                        <th scope="col">Proveedor</th>
                        <th scope="col">Telefono Cliente</th>
                        <th scope="col">Nota de Venta</th>
                    </tr>
                </thead>
                <tbody>
                {ordenes.map((elem,index)=>{
                    return(
                        <tr key={index}>
                            <td>
                                <MDBBtn floating className='btn-danger' 
                                    onClick={()=>onClickQuitarOC(elem)}
                                >
                                <MDBIcon fas icon="times" />
                                </MDBBtn>
                            </td>
                            <td>{elem.estado}</td>
                            <td>{elem.fechaoc}</td>
                            <td>{elem.num_parte_prov}</td>
                            <td>{elem.codigo_dess}</td>
                            <td>{elem.tipo}</td>
                            <td>{elem.descripcion}</td>
                            <td>{elem.cantidad}</td>
                            <td>{elem.costo_unitario}</td>
                            <td>{elem.costo_envio}</td>
                            <td>{elem.costo_total.length>5?elem.costo_total.substring(0,5):elem.costo_total}</td>
                            <td>{elem.divisa}</td>
                            <td>{elem.proveedor}</td>
                            <td>{elem.tele_cliente}</td>
                            <td>{elem.nota_venta}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </div>

            <div className='row'>
                <div className='col'>
                    <ReactToPrint
                        trigger={() => <MDBBtn color='success'><MDBIcon fas icon="print" /> IMPRIMIR INFORME</MDBBtn>}
                        content={() => tablaActRef.current}
                    />
                </div>
            </div>

            {/* MODALES */}
            <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Ingreso de OC exitosa!'} />
            <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error al ingresar OC!'} />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    proveedores: state.proveedores
});
  
const mapDispatchToProps = {
    dispatchProveedores: actions.fetchProveedores
};

export default connect(mapStateToProps, mapDispatchToProps)(IngresoOC);
import React, { useState, useEffect,useRef } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import ModalExito from "../../Modales/ModalExito";
import ModalError from "../../Modales/ModalError";
import {
  URL_INSERT_ARCHIVO_OC
} from "../../../config";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default function ModalArchivoOC({showModal,data,columns,file,token}) {
    //-------- variables de archivo excel --------//  
    const handleSubmission = async() => {
      var formData = new FormData();
      formData.append("image", file);
      formData.append("token", token.data[0].nombre)
      var response = await axios.post(URL_INSERT_ARCHIVO_OC, formData, { 
          headers: {
          'Content-Type': 'multipart/form-data'
          }
      }).then((resp)=>{
          if(resp.status===200){
              showModalExito.current();
              handleClose();
          }
      })
      .catch((error)=>{
          showModalError.current();
          handleClose();
      });
      console.log(response);
    };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    showModal.current = handleClickOpen
  }, [showModal])   

  //activador modal exito
  const showModalExito = useRef(null);
  const showModalError = useRef(null);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth='xl'
      >
        <DialogTitle id="alert-dialog-title">
          {"Pre-visualizacion de Ordenes de Compra a subir"}
        </DialogTitle>
        <DialogContent>
          <TableContainer>
              <Table>
                  <TableHead>
                    {data?.map((elem,index)=>{
                          if(index ===0){
                            return(
                              <TableRow key={index}>
                                  <TableCell>{elem[0]}</TableCell>
                                  <TableCell>{elem[1]}</TableCell>
                                  <TableCell>{elem[2]}</TableCell>
                                  <TableCell>{elem[3]}</TableCell>
                                  <TableCell>{elem[4]}</TableCell>
                                  <TableCell>{elem[5]}</TableCell>
                                  <TableCell>{elem[6]}</TableCell>
                                  <TableCell>{elem[7]}</TableCell>
                                  <TableCell>{elem[8]}</TableCell>
                                  <TableCell>{elem[9]}</TableCell>
                                  <TableCell>{elem[10]}</TableCell>
                              </TableRow>
                            )
                          }else void 0;
            
                      })}
                  </TableHead>
                  <TableBody>
                      {data?.map((elem,index)=>{
                          if(index !== 0){
                            return(
                              <TableRow key={index}>
                                  <TableCell>{elem[0]}</TableCell>
                                  <TableCell>{elem[1]}</TableCell>
                                  <TableCell>{elem[2]}</TableCell>
                                  <TableCell>{elem[3]}</TableCell>
                                  <TableCell>{elem[4]}</TableCell>
                                  <TableCell>{elem[5]}</TableCell>
                                  <TableCell>{elem[6]}</TableCell>
                                  <TableCell>{elem[7]}</TableCell>
                                  <TableCell>{elem[8]}</TableCell>
                                  <TableCell>{elem[9]}</TableCell>
                                  <TableCell>{elem[10]}</TableCell>
                              </TableRow>
                            )
                          }else void 0;

                      })}
                  </TableBody>
              </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={handleSubmission}>
            Guardar
          </Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODALES */}
      <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Ingreso de productos exitoso!'} />
      <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error al subir archivo de productos a stock!'} />
    </div>
  );
}
import React, { useState, useEffect } from "react";
/*mui */
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
/***/

function IngresoUbicacionPaso3({
    token,
    ubicacionesSelec
  }){
  /*data grid */
const columns = [
  {
    field: 'codigo_dess',
    headerName: 'Codigo DESS',
    width: 150,
  },
  {
    field: 'num_parte_prov',
    headerName: 'Num Parte',
    width: 110,
  },
  {
    field: 'descripcion',
    headerName: 'Descripcion',
    sortable: true,
    width: 160
  },
  {
    field: 'marca_repuesto',
    headerName: 'Marca Producto',
    sortable: false,
    width: 150,
    type: 'string',
  },
  {
    field: 'cantidad',
    headerName: 'Cantidad Recibida',
    sortable: false,
    width: 150,
    type: 'number'
  },
  {
    field: 'bodega',
    headerName: 'Bodega',
    sortable: false,
    width: 150
  },
  {
    field: 'rack',
    headerName: 'Rack',
    sortable: false,
    width: 150
  },
  {
    field: 'fila',
    headerName: 'Fila',
    sortable: false,
    width: 150
  },
  {
    field: 'caja',
    headerName: 'Caja',
    sortable: false,
    width: 150
  },
  {
    field: 'tienda',
    headerName: 'Tienda',
    sortable: false,
    width: 150
  },
];

  //-------- state -------//
  let [comprasSelecState, setComprasSelecState] = useState([]);
  let [pageSize,setPageSize] = useState(10);

  //--------effects --------
  useEffect(() => {
    setComprasSelecState(ubicacionesSelec);
    console.log(ubicacionesSelec)
  }, [ubicacionesSelec]);


  return (
    <React.Fragment>
      <div style={{ height: 550, width: '100%' }}>
        <DataGrid
          rows={comprasSelecState}
          columns={columns}
          getRowId={(row)=>row.id}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10,20,50]}
          disableSelectionOnClick
          components={{ Toolbar: GridToolbar }}
        />
      </div>
    </React.Fragment>
  );
}

export default (IngresoUbicacionPaso3);
import React, { useState, useEffect,useRef } from "react";
import { connect } from "react-redux";
import actions from "../../../actions";
import axios from "axios";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import ModalExito from "../../Modales/ModalExito";
import ModalError from "../../Modales/ModalError";
import { URL_UPDATE_COMPRA } from "../../../config";

function ModalActEstado({
  showModal,titulo,mensaje,token
}) {
  let [estadoEditar,setEstadoEdiar] = useState('');
  let [compraEditar,setCompraEditar] = useState({});

  /*LOGICA DE MODAL */
  /* Activadores modales de exito o error */
  const showModalExito = useRef(null);
  const showModalError = useRef(null);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (compra) => {
    if(compra){
      setCompraEditar(compra);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    showModal.current = handleClickOpen;
  }, [])  
  /****/

  let onClickGuardarCambiosEstado = async() => {
    console.log(compraEditar,estadoEditar)
    if(estadoEditar!=='') {
        var horarioActualizacion = await axios.post(URL_UPDATE_COMPRA,
        {compraEditar,token,estadoEditar})
        .then((resp)=>{
          if(resp.status===200){
              showModalExito.current();
              handleClose();
          }
        })
        .catch((error)=>{
            showModalError.current();
            handleClose();
        });
    }
}; 

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title" className="mb-3">
          {titulo}
        </DialogTitle>
        <DialogContent className="pt-3">
          <FormControl sx={{ width: 199 }} size='small'>
            <InputLabel id="demo-multiple-chip-label">Estado</InputLabel>
            <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                
                value={estadoEditar}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                onChange={(event)=> setEstadoEdiar(event.target.value)}
            >
                <MenuItem value={'RECIBIDO'}>RECIBIDO</MenuItem>
                <MenuItem value={'PENDIENTE'}>PENDIENTE</MenuItem>
                <MenuItem value={'ATRASADO'}>ATRASADO</MenuItem>
                <MenuItem value={'PROXIMO'}>PROXIMO</MenuItem>
                <MenuItem value={'INCOMPLETO'}>INCOMPLETO</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" autoFocus
            onClick={()=> onClickGuardarCambiosEstado()}
          >
            Ingresar
          </Button>
          <Button variant="contained" color="error"  autoFocus
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODALES */}
      <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Ingreso de producto exitoso!'} />
      <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error al ingresar producto a stock!'} />
    </div>
  );
}

export default (ModalActEstado);
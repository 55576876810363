import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export function showAlert(open, handleClose, message, type) {
    return (
        <React.Fragment>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert severity={type} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}


export const FETCH_ULTIMA_SOLICITUD_PDESARME = "FETCH_ULTIMA_SOLICITUD_PDESARME";
export const FETCH_ULTIMA_SOLICITUD_PDESARME_SUCCESS = "FETCH_ULTIMA_SOLICITUD_PDESARME_SUCCESS";
export const FETCH_ULTIMA_SOLICITUD_PDESARME_ERROR = "FETCH_ULTIMA_SOLICITUD_PDESARME_ERROR";

export const FETCH_PDESARME = "FETCH_PDESARME";
export const FETCH_PDESARME_SUCCESS = "FETCH_PDESARME_SUCCESS";
export const FETCH_PDESARME_ERROR = "FETCH_PDESARME_ERROR";

export const FETCH_ACCIONES_DESARME = "FETCH_ACCIONES_DESARME";
export const FETCH_ACCIONES_DESARME_SUCCESS = "FETCH_ACCIONES_DESARME_SUCCESS";
export const FETCH_ACCIONES_DESARME_ERROR = "FETCH_ACCIONES_DESARME_ERROR";

export const FETCH_COMPRAS = "FETCH_COMPRAS";
export const FETCH_COMPRAS_SUCCESS = "FETCH_COMPRAS_SUCCESS";
export const FETCH_COMPRAS_ERROR = "FETCH_COMPRAS_ERROR";

export const FETCH_ULT_COMPRAS_ING = "FETCH_ULT_COMPRAS_ING";
export const FETCH_ULT_COMPRAS_ING_SUCCESS = "FETCH_ULT_COMPRAS_ING_SUCCESS";
export const FETCH_ULT_COMPRAS_ING_ERROR = "FETCH_ULT_COMPRAS_ING_ERROR";

export const FETCH_ULT_ACTS_COMPRAS = "FETCH_ULT_ACTS_COMPRAS";
export const FETCH_ULT_ACTS_COMPRAS_SUCCESS = "FETCH_ULT_ACTS_COMPRAS_SUCCESS";
export const FETCH_ULT_ACTS_COMPRAS_ERROR = "FETCH_ULT_ACTS_COMPRAS_ERROR";

export const FETCH_SOLIC_SIN_MATCH = "FETCH_SOLIC_SIN_MATCH";
export const FETCH_SOLIC_SIN_MATCH_SUCCESS = "FETCH_SOLIC_SIN_MATCH_SUCCESS";
export const FETCH_SOLIC_SIN_MATCH_ERROR = "FETCH_SOLIC_SIN_MATCH_ERROR";

export const FETCH_ACT_COMPRAS = "FETCH_ACT_COMPRAS";
export const FETCH_ACT_COMPRAS_SUCCESS = "FETCH_ACT_COMPRAS_SUCCESS";
export const FETCH_ACT_COMPRAS_ERROR = "FETCH_ACT_COMPRAS_ERROR";

export const FETCH_PEDIDOS_DUMP = "FETCH_PEDIDOS_DUMP";
export const FETCH_PEDIDOS_DUMP_SUCCESS = "FETCH_PEDIDOS_DUMP_SUCCESS";
export const FETCH_PEDIDOS_DUMP_ERROR = "FETCH_PEDIDOS_DUMP_ERROR";


//Stock
export const FETCH_STOCK = "FETCH_STOCK";
export const FETCH_STOCK_SUCCESS = "FETCH_STOCK_SUCCESS";
export const FETCH_STOCK_ERROR = "FETCH_STOCK_ERROR";

export const UPDATE_STOCK = "UPDATE_STOCK";
export const UPDATE_STOCK_SUCCESS = "UPDATE_STOCK_SUCCESS";
export const UPDATE_STOCK_ERROR = "UPDATE_STOCK_ERROR";

export const FETCH_PROVEEDORES = "FETCH_PROVEEDORES";
export const FETCH_PROVEEDORES_SUCCESS = "FETCH_PROVEEDORES_SUCCESS";
export const FETCH_PROVEEDORES_ERROR = "FETCH_PROVEEDORES_ERROR";

//API STOCK
export const FETCH_BODEGAS = "FETCH_BODEGAS";
export const FETCH_BODEGAS_SUCCESS = "FETCH_BODEGAS_SUCCESS";
export const FETCH_BODEGAS_ERROR = "FETCH_BODEGAS_ERROR";

export const FETCH_RACKS_BY_ID_BODEGA = "FETCH_RACKS_BY_ID_BODEGA";
export const FETCH_RACKS_BY_ID_BODEGA_SUCCESS = "FETCH_RACKS_BY_ID_BODEGA_SUCCESS";
export const FETCH_RACKS_BY_ID_BODEGA_ERROR = "FETCH_RACKS_BY_ID_BODEGA_ERROR";

export const FETCH_FILAS_BY_ID_RACK = "FETCH_FILAS_BY_ID_RACK";
export const FETCH_FILAS_BY_ID_RACK_SUCCESS = "FETCH_FILAS_BY_ID_RACK_SUCCESS";
export const FETCH_FILAS_BY_ID_RACK_ERROR = "FETCH_FILAS_BY_ID_RACK_ERROR";

export const FETCH_CAJAS_BY_ID_FILA = "FETCH_CAJAS_BY_ID_FILA";
export const FETCH_CAJAS_BY_ID_FILA_SUCCESS = "FETCH_CAJAS_BY_ID_FILA_SUCCESS";
export const FETCH_CAJAS_BY_ID_FILA_ERROR = "FETCH_CAJAS_BY_ID_FILA_ERROR";

export const PUT_COMPRA_STOCK = "PUT_COMPRA_STOCK";
export const PUT_COMPRA_STOCK_SUCCESS = "PUT_COMPRA_STOCK_SUCCESS";
export const PUT_COMPRA_STOCK_ERROR = "PUT_COMPRA_STOCK_ERROR";

export const POST_PRODUCTO_STOCK = "POST_PRODUCTO_STOCK";
export const POST_PRODUCTO_STOCK_SUCCESS = "POST_PRODUCTO_STOCK_SUCCESS";
export const POST_PRODUCTO_STOCK_ERROR = "POST_PRODUCTO_STOCK_ERROR";
import React, { useState, useEffect,useRef } from "react";
import { connect } from "react-redux";
import actions from "../../../actions";
import axios from "axios";
import ModalExito from "../../Modales/ModalExito";
import ModalError from "../../Modales/ModalError";
import { URL_VINCULAR_OC } from "../../../config";
/**mui */
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DataGrid,GridActionsCellItem,GridToolbar} from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { Alert, Snackbar, TextField } from "@mui/material";

function ModalForzarOC({
  showModal,titulo,mensaje,token, compras,dispathcPedidosDump
}) {

    const columns = [
        {
            field: 'estado',
            headerName: 'Estado',
            width: 150,
            renderCell: (elem) => (
              <div>
              <Chip icon={elem.row.estado === 'RECIBIDO'?<CheckIcon />:<ErrorOutlineIcon />} label={elem.row.estado} variant="outlined" 
                color={elem.row.estado === 'RECIBIDO'? "success" :elem.row.estado === 'ATRASADO'? 'error': "warning"}
              />
              </div>
            )
        },
        {
            field: 'fechaoc',
            headerName: 'Fecha Compra',
            width: 150,
        },
        {
            field: 'fecha_estimada_llegada',
            headerName: 'Fecha Est Llegada',
            width: 150,
        },
        {
          field: 'num_oc',
          headerName: 'Num Orden',
          width: 150,
        },
        {
            field: 'tipo',
            headerName: 'Tipo',
            width: 150,
        },
        {
          field: 'codigo_dess',
          headerName: 'Codigo DESS',
          width: 150,
        },
        {
          field: 'num_parte_prov',
          headerName: 'Num Parte',
          width: 110,
        },
        {
          field: 'descripcion',
          headerName: 'Descripcion',
          width: 350,
          sortable:true
        },
        {
            field: 'cantidad',
            headerName: 'Cantidad Solicitada',
            sortable: false,
            width: 150
        },
        {
            field: 'costo_unitario',
            headerName: 'Costo Unitario',
            sortable: false,
            width: 150
        },
        {
            field: 'costo_total',
            headerName: 'Costo Total',
            sortable: false,
            width: 150
        },
        {
            field: 'usuario_ingreso',
            headerName: 'Vendedor',
            sortable: false,
            width: 150
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Vincular OC',
            getActions: (params) => [
              <GridActionsCellItem  icon={<AddLinkIcon />} 
                label="Delete" 
                onClick={()=>vincularOC(params.row)}
                title='VINCULAR ORDEN DE COMPRA'
                />,
            ]
        }
      ];
  /*LOGICA DE MODAL */
  let [pageSize,setPageSize] = useState(10);
  let [snackbar, setSnackbar] = useState(null);
  let handleCloseSnackbar = () => setSnackbar(null);
  let [pedidoDump,setPedidoDump] = useState();
  let [textoBuscado,setTextoBuscado] = useState('');
  /* Activadores modales de exito o error */
  const showModalExito = useRef(null);
  const showModalError = useRef(null);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (row) => {
    setPedidoDump(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let vincularOC = async(row)=>{
    try {
      // Make the HTTP request to save in the backend
      await axios.post(URL_VINCULAR_OC,
        {row,pedidoDump,token})
        .then((resp)=>{
          if(resp.status===200){      
            setSnackbar({ children: 'Accion realizada con éxito', severity: 'success' });
            dispathcPedidosDump();
            handleClose();
          }
      })
    } catch (error) {
      setSnackbar({ children: "No se pudo realizar la petición", severity: 'error' });
      handleClose();
    }
  }

  let comprasFiltroFlexible = compras.data.filter(pieza => {
    var textoTotal = JSON.stringify(pieza);
    var count = -1
    for(let i=0; i<textoBuscado.split(' ').length;i++){
        if(textoTotal.indexOf(textoBuscado.toUpperCase().split(' ')[i]) >= 0){
            count ++;
        }
    }   
    if(count >= 0 && count===textoBuscado.split(' ').length-1){
        return true
    }else {
        return false
    }
  });

  useEffect(() => {
    showModal.current = handleClickOpen;
  }, [showModal])  
  /****/

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth='xl'
      >
        <DialogTitle id="alert-dialog-title" className="mb-3">
          <p>ORDENES DE COMPRA</p>
        </DialogTitle>
        <DialogContent className="pt-3">
            <div style={{ height: 600, width: '100%' }}>
                <TextField size='small' id="filled-basic" label="Buscar" variant="outlined" 
                    onChange={(event)=>setTextoBuscado(event.target.value)}
                />
                <DataGrid
                    rows={comprasFiltroFlexible}
                    columns={columns}
                    getRowId={(row)=>row.codigo}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10,20,50]}
                    disableSelectionOnClick
                    components={{ Toolbar: GridToolbar }}
                    getRowClassName={(params) => params.row.ingresado&& 'clase-prueba'}
                />
            </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error"  autoFocus
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODALES */}
      <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Ingreso de producto exitoso!'} />
      <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error al ingresar producto a stock!'} />
      {/*------- snackbar --------*/}
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
    compras: state.compras,
    pedidosDump: state.pedidosDump
});
  
const mapDispatchToProps = {
    dispatchFetchCompras: actions.fetchCompras,
    dispathcPedidosDump: actions.fetchPedidosDump  
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalForzarOC);
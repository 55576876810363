import React, { useState, useEffect } from 'react';
import TicketsService from '../../services/Soporte/TicketsService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TicketDto from '../../models/TicketDto';
import { showLoader } from '../../services/utils/LoaderService';
import { showAlert } from '../../services/utils/SnackbarService';

export default function AddSupportTicket({ token, ticketToEdit, onSave, onClose }) {
    /**
     * Estados
     */
    const [alertType, setAlertType] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState();  
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [text, setText] = useState('');
    const [title, setTitle] = useState(null);
    const [ticketType, setTicketType] = React.useState(null);

    /**
     * Funciones
     */
    const changeTicketType = (event) => {
        setTicketType(event.target.value);
    };

    const changeTitle = (event) => {
        setTitle(event.target.value);
    };

    const saveTicket = () => {
        setIsLoading(true);
        const ticketDto = new TicketDto();
        ticketDto.titulo = title;
        ticketDto.autor = token.data[0].nombre;
        ticketDto.texto = text;
        ticketDto.tipo_id = ticketType;
        ticketDto.estado = 1;
        ticketDto.codigo = ticketToEdit ? ticketToEdit.codigo : 0;

        if(!ticketToEdit){
            TicketsService.postTicket(ticketDto)
                .then((resp) => {
                    setIsLoading(false);
                    setSnackBarMessage(resp.mensaje);
                    setAlertType('success');
                    setOpenSnackbar(true);
                    onSave();
                })
                .catch((error) => {
                    setIsLoading(false);
                    setSnackBarMessage('ERROR');
                    setAlertType('error');
                    setOpenSnackbar(true);
                    onSave();
                });
        } else {
            TicketsService.putTicket(ticketDto, ticketToEdit.codigo)
                .then((resp) => {
                    setIsLoading(false);
                    setSnackBarMessage(resp.mensaje);
                    setAlertType('success');
                    setOpenSnackbar(true);
                    onSave();
                })
                .catch((error) => {
                    setIsLoading(false);
                    setSnackBarMessage('ERROR');
                    setAlertType('error');
                    setOpenSnackbar(true);
                    onSave();
                });
        }
    };

    const onCancel = () => {
        onClose();
    };

    //Estilos del editor
    const editorStyle = {
        height: '500px', // Ajusta el valor según tus preferencias
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if(ticketToEdit){
            setTitle(ticketToEdit.titulo);
            setTicketType(ticketToEdit.tipo_id);
            setText(ticketToEdit.texto);
        }
    }, [ticketToEdit]);

    return (
        <React.Fragment>
            {showAlert(openSnackbar, handleCloseSnackbar, snackBarMessage, alertType)}
            {showLoader(isLoading)}
            <br />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <TextField fullWidth id="outlined-basic" label="Titulo" variant="outlined"
                            value={title}
                            onChange={changeTitle}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={ticketType}
                                label="Tipo"
                                onChange={changeTicketType}
                            >
                                <MenuItem value={1}>Error</MenuItem>
                                <MenuItem value={2}>Requerimiento</MenuItem>
                                <MenuItem value={3}>Mejora</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <br />
            <div className='col' style={{ height: 600, width: '100%' }}>
                <h5>Descripción</h5>
                <ReactQuill
                    value={text}
                    style={editorStyle}
                    onChange={setText}
                    modules={{
                        toolbar: [
                            [{ 'header': '1' }, { 'header': '2' }],
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            ['link'],
                            ['clean']
                        ]
                    }}
                />
            </div>
            <div className='col'>
                <Button onClick={saveTicket} variant="contained">Guardar Ticket</Button>
                <Button className="mx-4" onClick={onCancel} variant="contained" color='error'>Cancelar</Button>
            </div>
            <br />
        </React.Fragment>
    );
}
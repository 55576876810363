import {
    fetcUltimaSolicitudIngresoPiezasDesarme,fetchPiezasDesarme,fetchAccionesDesarme,
} from "./DesarmePiezas";
import {fetchCompras,fetchUtlActCompras,fecthSolicSinMatch,fetchUltComprasIng, fetchProveedores} from './Compras';
import {fetchStock, fetchBodegas, fetchRacksByIdBodega, fetchFilasByIdRack, fetchCajasByIdFila, putCompraByIdCaja, postProductoStock} from './Stock';
import { fetchActCompras } from "./Compras";
import { fetchPedidosDump } from "./Compras";

var actions = { 
    fetcUltimaSolicitudIngresoPiezasDesarme,
    fetchPiezasDesarme,
    fetchAccionesDesarme,
    fetchCompras,
    fetchUtlActCompras,
    fecthSolicSinMatch,
    fetchUltComprasIng,
    fetchStock,
    fetchBodegas,
    fetchRacksByIdBodega,
    fetchFilasByIdRack,
    fetchCajasByIdFila,
    putCompraByIdCaja,
    postProductoStock,
    fetchProveedores,
    fetchActCompras,
    fetchPedidosDump
};
export default actions;

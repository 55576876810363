import {Alert} from "@mui/material";
import React,{useState,useEffect} from 'react';
import axios from "axios";
import actions from "../../actions";
import { connect } from "react-redux";
import {Paginacion} from '../../services/Services';

import {
    URL_INSERT_PIEZAS_DESARME,
    URL_CANCELAR_INGRESO,
    URL_CONFIRMAR_INRESO
} from "../../config";

function IngresoPiezas({piezas,dispatchUltSoliPDesarme,token}) {
	let [selectedFile, setSelectedFile] = useState();
    let [isSelected,setIsSelected] = useState(false);
    let [horaIngresoArchivo,setHoraIngresoArchivo ] = useState("");
    //----------- paginacion de tabla ----------------//
    let [pageSize] = useState(10);
    let [pages, setPages] = useState([]);
    let [currentPageIndex, setCurrentPageIndex] = useState(0);
    let [piezasTabla, setPiezasTabla] = useState([]);
    //------------VARIABLES DE FILTROS-------------------//
    let [codigoBuscado, setCodigoBuscado] = useState("");
    let [descripcionBuscada, setDescripcionBuscada] = useState("");
    let [ubicacionBuscada, setUbicacionBuscada] = useState("");
    let [cajaBuscada, setCajaBuscada] = useState("");
    let [marcaAutoBuscada, setMarcaAutoBuscada] = useState("");
    let [carroceriaAutoBuscada, setCarroceriaAutoBuscada] = useState("");
    let [motorAutoBuscado, setMotorAutoBuscado] = useState("");
    let [numeroParteBuscado, setNumeroParteBuscado] = useState("");
    let [numeroAutoBuscado, setNumeroAutoBuscado] = useState("");
    //------------VARIABLES DE FILTROS fin -------------------//

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

	const handleSubmission = async() => {
        var formData = new FormData();
        var piezas = document.querySelector('#file');
        formData.append("image", piezas.files[0]);
        formData.append("token",token.data[0].nombre)
        var horaResponse = await axios.post(URL_INSERT_PIEZAS_DESARME, formData, { 
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        setHoraIngresoArchivo(horaResponse.data)
        setTimeout(() => { dispatchUltSoliPDesarme(horaResponse.data) }, 2000);
    };
    
    //------------FILTROS!!-------------------//
    let piezasFiltradasCodigo = piezas.data.filter(pieza => 
        pieza.codigo.indexOf(codigoBuscado.toUpperCase()) >= 0
    );
    let piezasFiltradasDescripcion = piezasFiltradasCodigo.filter(pieza => 
        pieza.descripcion.indexOf(descripcionBuscada.toUpperCase()) >= 0
    );
    let piezasFiltradasUbicacion = piezasFiltradasDescripcion.filter(pieza => 
        pieza.ubicacion.indexOf(ubicacionBuscada.toUpperCase()) >= 0
    );
    let piezasFiltradasCaja = piezasFiltradasUbicacion.filter(pieza => 
        pieza.caja.indexOf(cajaBuscada.toUpperCase()) >= 0
    );
    let piezasFiltradasMarcaAuto = piezasFiltradasCaja.filter(pieza => 
        pieza.auto_marca.indexOf(marcaAutoBuscada.toUpperCase()) >= 0
    );
    let piezasFiltradasCarroceria = piezasFiltradasMarcaAuto.filter(pieza => 
        pieza.auto_carroceria.indexOf(carroceriaAutoBuscada.toUpperCase()) >= 0
    );
    let piezasFiltradasMotor = piezasFiltradasCarroceria.filter(pieza => 
        pieza.auto_motor.indexOf(motorAutoBuscado.toUpperCase()) >= 0
    );
    let piezasFiltradasNumParte = piezasFiltradasMotor.filter(pieza => 
        pieza.num_parte.indexOf(numeroParteBuscado.toUpperCase()) >= 0
    );
    let piezasFiltradasNumAuto = piezasFiltradasNumParte.filter(pieza => 
        pieza.num_auto.indexOf(numeroAutoBuscado.toUpperCase()) >= 0
    );
    //------------FILTROS fin-------------------//

    //Funcion de paginacion
    useEffect(() => {
        //calcula el numro de paginas y crea un objeto pagina
        setPages(Paginacion.getPagesArray(piezasFiltradasNumAuto, pageSize));
        //paging
        let pagedArray = Paginacion.getRowsByPageIndex(
            piezasFiltradasNumAuto,
            currentPageIndex,
            pageSize
        );
        //get sorted products
        setPiezasTabla(pagedArray);
    }, [piezas.loading,pageSize,currentPageIndex, codigoBuscado,descripcionBuscada,ubicacionBuscada,cajaBuscada,marcaAutoBuscada,carroceriaAutoBuscada,motorAutoBuscado,numeroAutoBuscado,numeroParteBuscado])
    
    useEffect(() =>{
        setCurrentPageIndex(0)
    },[])

    let onPageIndexClicked = (clickedPageIndex) => {
        if (clickedPageIndex >= 0 && clickedPageIndex < pages.length)
        setCurrentPageIndex(clickedPageIndex);
    };

    let clickCancelarIngreso = async() => {
        var response = await axios.get(`${URL_CANCELAR_INGRESO}/${horaIngresoArchivo}`);
        dispatchUltSoliPDesarme(horaIngresoArchivo);
    };

    let clickConfirmarIngreso = async() => {
        var response = await axios.get(`${URL_CONFIRMAR_INRESO}/${horaIngresoArchivo}`);
        dispatchUltSoliPDesarme('xxx');
    };

    let restablecerFiltros = () => {
        setCodigoBuscado("");
        setDescripcionBuscada("")
        setUbicacionBuscada("");
        setCajaBuscada("");
        setMarcaAutoBuscada("");
        setCarroceriaAutoBuscada("");
        setMotorAutoBuscado("");
        setNumeroParteBuscado("");
        setNumeroAutoBuscado("");
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
          );

        /*Array.from(document.querySelectorAll("select")).forEach(
            select => (select.value = "")
        );*/
    };  

    return (
        <React.Fragment>
            <br/>
            <div>
                <div className='container'>
                    <h5>SUBIR ARCHIVO DE PIEZAS EN DESARME</h5>
                    <hr/>
                    <div className='row'>
                        <div className='col'>
                            <label htmlFor="formFile" className="form-label" >
                            Arrastre o seleccione un archivo.
                            </label>
                            <input className="form-control" type="file" name="file" id="file" onChange={changeHandler} />
                            <button className="btn btn-primary mt-1 mb-2" type="submit" onClick={handleSubmission}>Submit</button>
                        </div>
                        {isSelected ? (
                            <div className='col'>
                                <p>Nombre de archivo: {selectedFile.name}</p>
                                <p>Tipo de archivo: {selectedFile.type}</p>
                                <p>Tamaño en bytes bytes: {selectedFile.size}</p>
                            </div>
                            ) : (
                                <div className='col mt-4'>
                                <Alert severity="info"> Seleccione un archivo para actualizar los estados de los pedidos.</Alert>
                            </div>
                        )}
                    </div>
                </div>
                <div className='container'>
                    <h4>FILTROS DE BUSQUEDA</h4>
                    <hr/>
                    <div className='row'>
                        <div className='col'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Codigo</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" 
                            onChange= {(event) => {setCodigoBuscado(event.target.value)}} 
                            />
                        </div>
                        <div className='col'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Descripcion</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" 
                            onChange= {(event) => {setDescripcionBuscada(event.target.value)}}
                            />
                        </div>
                        <div className='col'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Ubicacion</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" 
                            onChange= {(event) => {setUbicacionBuscada(event.target.value)}}
                            />
                        </div>
                        <div className='col'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Caja</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" 
                            onChange= {(event) => {setCajaBuscada(event.target.value)}}
                            />
                        </div>
                        <div className='col'>
                            <button type="button" className="btn btn-primary btn-sm"
                            onClick={() => {restablecerFiltros()}}
                            >
                                restablecer Filtros
                            </button>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Marca Auto</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" 
                            onChange= {(event) => {setMarcaAutoBuscada(event.target.value)}}
                            />
                        </div>
                        <div className='col'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Carroceria Auto</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" 
                            onChange= {(event) => {setCarroceriaAutoBuscada(event.target.value)}}
                            />
                        </div>
                        <div className='col'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Motor Auto</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" 
                            onChange= {(event) => {setMotorAutoBuscado(event.target.value)}}
                            />
                        </div>
                        <div className='col'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Numero Parte</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" 
                            onChange= {(event) => {setNumeroParteBuscado(event.target.value)}}
                            />
                        </div>
                        <div className='col'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Numero Auto</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" 
                            onChange= {(event) => {setNumeroAutoBuscado(event.target.value)}}
                            />
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <hr/>
                    <h4>PIEZAS A INGRESAR</h4>
                    {!isSelected ?
                        <div className="d-flex justify-content-center">
                            <h5>NO SE HA CARGADO ARCHIVO CON PIEZAS A INGRESAR</h5>
                        </div>
                        :
                        <div>
                        <table className="table table-striped">
                        <thead className='table-dark'>
                            <tr>
                            <th scope="col">Codigo</th>
                            <th scope="col">Descripcion</th>
                            <th scope="col">Ubicacion</th>
                            <th scope="col">Caja</th>
                            <th scope="col">Marca Auto</th>
                            <th scope="col">Carroceria Auto</th>
                            <th scope="col">Motor Auto</th>
                            <th scope="col">Num Parte</th>
                            <th scope="col">Num Auto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {piezasTabla.map((elem,index)=>{
                                return(
                                <tr key={index}>
                                    <td>{elem.codigo}</td>
                                    <td>{elem.descripcion}</td>
                                    <td>{elem.ubicacion}</td>
                                    <td>{elem.caja}</td>
                                    <td>{elem.auto_marca}</td>
                                    <td>{elem.auto_carroceria}</td>
                                    <td>{elem.auto_motor}</td>
                                    <td>{elem.num_parte}</td>
                                    <td>{elem.num_auto}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                        </table>
                        <ul className="pagination justify-content-center mt-1">
                            <li
                                className="page-item"
                                onClick={() => {
                                    onPageIndexClicked(currentPageIndex - 1);
                                }}
                            >
                                <a
                                className="page-link bg-secondary text-white"
                                href="/#"
                                onClick={(event) => {
                                    event.preventDefault();
                                }}
                                >
                                Prev
                                </a>
                            </li>
                            {pages.map((page) => (
                                <li
                                key={page.pageIndex}
                                className={
                                    currentPageIndex === page.pageIndex
                                    ? "page-item active"
                                    : "page-item"
                                }
                                onClick={() => {
                                    onPageIndexClicked(page.pageIndex);
                                }}
                                >
                                <a
                                    className="page-link"
                                    href="/#"
                                    onClick={(event) => {
                                    event.preventDefault();
                                    }}
                                >
                                    {page.pageIndex + 1}
                                </a>
                                </li>
                            ))}

                            <li
                                className="page-item"
                                onClick={() => {
                                    onPageIndexClicked(currentPageIndex + 1);
                                }}
                            >
                                <a
                                className="page-link bg-secondary text-white"
                                href="/#"
                                onClick={(event) => {
                                    event.preventDefault();
                                }}
                                >
                                Next
                                </a>
                            </li>
                        </ul>
                        <button type="button" className="btn btn-danger btn-md" data-bs-toggle="modal" data-bs-target="#cancelacionmodal" >
                            Cancelar Ingreso de piezas
                        </button>
                        <button type="button" className="btn btn-success btn-md" data-bs-toggle="modal" data-bs-target="#confirmarIngreso">
                            Enviar Solicitud ingreso de piezas
                        </button>
                        </div>
                    }
                </div>
            </div>
            {/* ---------- MODAL CANCELAR INGRESO ---------- */}               
            <div className="modal fade" id="cancelacionmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">ATENCION!</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        Estas a punto de cancelar el ingreso de piezas del archivo subido!
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            Atras
                        </button>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => {clickCancelarIngreso()}}>
                            Cancelar Ingreso
                        </button>
                    </div>
                    </div>
                </div>
            </div>

            {/* ---------- MODAL CONFIRMAR INGRESO ---------- */}               
            <div className="modal fade" id="confirmarIngreso" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">CONFIRMACION DE INGRESO!</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        Estas a punto de enviar las piezas al sistema para revision.
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            Atras
                        </button>
                        <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={() => {clickConfirmarIngreso()}}>
                            Confirmar Ingreso
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    piezas: state.desarmeSolicitudes,
});
  
const mapDispatchToProps = {
    dispatchUltSoliPDesarme: actions.fetcUltimaSolicitudIngresoPiezasDesarme,
};

export default connect(mapStateToProps, mapDispatchToProps)(IngresoPiezas);
import React,{useEffect,useState, useRef, useCallback} from 'react';
import { connect } from "react-redux";
import actions from "../../actions";
import axios from "axios";
import {ExcelRenderer} from 'react-excel-renderer';  
import {
    URL_ELIMINAR_COMPRA,
    URL_EDITAR_COMPRA
} from '../../config';
import ModalArchivoOC from './Modales/ModalArchivoOC';
import { hasRoleAdmin } from '../../core/auth/auth';
import ModalExito from '../Modales/ModalExito';
import ModalError from '../Modales/ModalError';
import ModalActEstado from './Modales/ModalActEstado';
import ModalIngresoOC from './Modales/ModalIngresoOC';
import ModalActCostos from './Modales/ModalActCostos';
/*import mui components */
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DataGrid,GridActionsCellItem,GridToolbar} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import { Alert, Snackbar, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


/****/
function computeMutation(newRow, oldRow) {
    if (newRow.codigo_dess !== oldRow.codigo_dess) {
      return `codigo_dess de '${oldRow.codigo_dess}' a '${newRow.codigo_dess}'`;
    }
    if (newRow.descripcion !== oldRow.descripcion) {
      return `descripcion de '${oldRow.descripcion}' a '${newRow.descripcion}'`;
    }
    if (newRow.cantidad !== oldRow.cantidad) {
      return `cantidad solicitada de '${oldRow.cantidad}' a '${newRow.cantidad}'`;
    }
    if (newRow.costo_unitario !== oldRow.costo_unitario) {
      return `costo_unitario de '${oldRow.costo_unitario}' a '${newRow.costo_unitario}'`;
    }
    if (newRow.costo_envio !== oldRow.costo_envio) {
      return `costo_envio de '${oldRow.costo_envio}' a '${newRow.costo_envio}'`;
    }
    if (newRow.costo_total !== oldRow.costo_total) {
      return `costo_total de '${oldRow.costo_total}' a '${newRow.costo_total}'`;
    }
    if (newRow.usuario_ingreso !== oldRow.usuario_ingreso) {
        return `usuario_ingreso de '${oldRow.usuario_ingreso}' a '${newRow.usuario_ingreso}'`;
    }
    return null;
}

const estados = [
  'RECIBIDO',
  'PENDIENTE',
  'ATRASADO',
  'PROXIMO',
  'INCOMPLETO'
];

function SeguimientoOC({token,compras,dispatchFetchCompras}) {

    /*data grid */
const columns = [
    {
        field: 'estado',
        headerName: 'Estado',
        width: 150,
        renderCell: (elem) => (
          <div>
          <Chip icon={elem.row.estado === 'RECIBIDO'?<CheckIcon />:<ErrorOutlineIcon />} label={elem.row.estado} variant="outlined" 
            color={elem.row.estado === 'RECIBIDO'? "success" :elem.row.estado === 'ATRASADO'? 'error': "warning"}
          />
          </div>
        )
    },
    {
      field: 'codigo_invoice',
      headerName: 'Codigo Invoice',
      width: 150,
    },
    {
        field: 'fechaoc',
        headerName: 'Fecha Compra',
        width: 150,
    },
    {
        field: 'fecha_estimada_llegada',
        headerName: 'Fecha Est Llegada',
        width: 150,
    },
    {
      field: 'num_oc',
      headerName: 'Num Orden',
      width: 150,
    },
    {
        field: 'tipo',
        headerName: 'Tipo',
        width: 150,
    },
    {
      field: 'codigo_dess',
      headerName: 'Codigo DESS',
      width: 150,
      editable:hasRoleAdmin(token)
    },
    {
      field: 'num_parte_prov',
      headerName: 'Num Parte',
      width: 110,
    },
    {
      field: 'descripcion',
      headerName: 'Descripcion',
      width: 350,
      sortable:true,
      editable:hasRoleAdmin(token),
    },
    {
        field: 'cantidad',
        headerName: 'Cantidad Solicitada',
        sortable: false,
        width: 150,
        editable:hasRoleAdmin(token)
    },
    {
      field: 'cantidad_recibida',
      headerName: 'Cantidad Recibida',
      sortable: false,
      width: 150
    },
    {
        field: 'costo_unitario',
        headerName: 'Costo Unitario',
        sortable: false,
        width: 150,
        editable:hasRoleAdmin(token)
    },
    {
        field: 'costo_envio',
        headerName: 'Costo Envio',
        sortable: false,
        width: 150,
        editable:hasRoleAdmin(token)
    },
    {
        field: 'costo_total',
        headerName: 'Costo Total',
        sortable: false,
        width: 150,
        editable:hasRoleAdmin(token)
    },
    {
        field: 'usuario_ingreso',
        headerName: 'Vendedor',
        sortable: false,
        width: 150,
        editable:hasRoleAdmin(token)
    },
    {
        field: 'tele_cliente',
        headerName: 'Telefono Cliente',
        sortable: false,
        width: 150,
        editable:hasRoleAdmin(token)
    },
    {
        field: 'nota_venta',
        headerName: 'Nota de Venta',
        sortable: false,
        width: 150,
        editable:hasRoleAdmin(token)
    },
    {
        field: 'proveedor',
        headerName: 'Proveedor',
        sortable: true,
        width: 150,
        editable:hasRoleAdmin(token)
    },
    {
        field: 'actions',
        type: 'actions',
        getActions: (params) => [
          <GridActionsCellItem  icon={<DeleteIcon />} 
            label="Delete" 
            onClick={()=>confirmarEliminarCompra(params.row)}
            disabled={!hasRoleAdmin(token)}
            />,
        ]
    }
  ];

    let [invoiceBuscada] = useState();
    
    const handleChangeEstado = (event) => {
      const {
        target: { value },
      } = event;
      setEstadoBuscado(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    const handleChangeTipo = (event) => {
        const {
          target: { value },
        } = event;
        setTipoBuscado(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };
    /** */
    let [compraAccion,setCompraAccion] = useState()
    let [confirmarEliminar,setConfirmarElim] = useState(false);
    let [comprasState,setComprasState] = useState([]);
    let [compraEditar] = useState();
    let [costoUnitEditar] = useState('');
    let [costoEnvioEditar] = useState('');
    //----------- orden de tabla ----------------//
    //----------- paginacion de tabla ----------------//
    let [pageSize,setPageSize] = useState(10);
    //------------VARIABLES DE FILTROS-------------------//
    let [codigoBuscado] = useState("");
    let [descripcionBuscada] = useState("");
    let [fechaBuscada0] = useState();
    let [fechaBuscada1, setFechaBuscada1] = useState("01-04-2000");
    let [fechaBuscada2, setFechaBuscada2] = useState("01-04-2050");
    let [costoBuscado1, setCostoBuscado1] = useState("00");
    let [costoBuscado2, setCostoBuscado2] = useState("1000000");
    let [estadoBuscado, setEstadoBuscado] = useState([]);
    let [tipoBuscado, setTipoBuscado] = useState([]);
    let [textoBuscado,setTextoBuscado] = useState('');
    let [proveedorBuscado,setProveedorBuscado] = useState('');
    //-------- variables edicion compras ------- //
    let [promiseArguments, setPromiseArguments] = useState(null);
    let noButtonRef = useRef(null);
    let [snackbar, setSnackbar] = useState(null);
    let handleCloseSnackbar = () => setSnackbar(null);

    //------------LOGICA DE FILTROS!!-------------------//
    let comprasFiltFecha0 = compras.data.filter(elem => 
        fechaBuscada0?
        elem.fechaingsist.indexOf(fechaBuscada0.toLocaleDateString("en-GB")) >= 0
        : true
    );

    /* select Variables*/
    let comprasFiltInvoice = comprasFiltFecha0.filter(elem => 
        invoiceBuscada?
        elem.fechaingsist.indexOf(invoiceBuscada.fechaingsist) >= 0 &&
        elem.horaingsist.indexOf(invoiceBuscada.horaingsist) >= 0
        :
        true
    );
    let comprasFiltCodigo = comprasFiltInvoice.filter(elem => 
        elem.num_oc.indexOf(codigoBuscado.toUpperCase()) >= 0
    );
    let comprasFiltDescr = comprasFiltCodigo.filter(elem => 
        elem.descripcion.indexOf(descripcionBuscada.toUpperCase()) >= 0
    );
    let comprasFiltEstado = comprasFiltDescr.filter(elem => 
        estadoBuscado.length===0? true :
        estadoBuscado.includes(elem.estado) 
    );
    let comprasFiltTipo = comprasFiltEstado.filter(elem => 
        tipoBuscado.length===0? true :
        tipoBuscado.includes(elem.tipo) 
    );
    let comprasFiltProv = comprasFiltTipo.filter(elem => 
        elem.proveedor.indexOf(proveedorBuscado.toUpperCase()) >= 0
    );
/**
    let comprasFiltRangoFecha = comprasFiltProv.filter(elem => 
        new Date(elem.fechaoc?.split('-').reverse().join('-')) >= new Date(fechaBuscada1.split('-').reverse().join('-')) &&
        new Date(elem.fechaoc?.split('-').reverse().join('-')) <= new Date(fechaBuscada2.split('-').reverse().join('-'))
    );

    let comprasFiltRangoCosto = comprasFiltRangoFecha.filter(elem => 
        parseInt(elem.costo_total) > parseInt(costoBuscado1) &&
        parseInt(elem.costo_total) < parseInt(costoBuscado2)
    );
 */
    let comprasFiltroFlexible = comprasFiltProv.filter(pieza => {
        var textoTotal = JSON.stringify(pieza)
        var count = -1
        for(let i=0; i<textoBuscado.split(' ').length;i++){
            if(textoTotal.indexOf(textoBuscado.toUpperCase().split(' ')[i]) >= 0){
                count ++;
            }
        }   
        if(count >= 0 && count===textoBuscado.split(' ').length-1){
            return true
        }else {
            return false
        }
    });

    /** Edicion de compras */
    
    const handleEntered = () => {
        // The `autoFocus` is not used because, if used, the same Enter that saves
        // the cell triggers "No". Instead, we manually focus the "No" button once
        // the dialog is fully open.
        // noButtonRef.current?.focus();
      }; 
  
    const processRowUpdate = useCallback(
        (newRow, oldRow) =>
        //console.log(newRow, oldRow)
           new Promise((resolve, reject) => {
            const mutation = computeMutation(newRow, oldRow);
            if (mutation) {
              // Save the arguments to resolve or reject the promise later
              setPromiseArguments({ resolve, reject, newRow, oldRow });
            } else {
              resolve(oldRow); // Nothing was changed
            }
          }),
        [],  
      );
  
      const handleNo = () => {
        const { oldRow, resolve } = promiseArguments;
        resolve(oldRow); // Resolve with the old row to not update the internal state
        setPromiseArguments(null);
      };
    
      const handleYes = async () => {
        const { newRow, oldRow, reject, resolve } = promiseArguments;
        try {
          // Make the HTTP request to save in the backend
          //const response = await mutateRow(newRow);
          await axios.post(URL_EDITAR_COMPRA,
            {newRow,token,oldRow})
            .then((resp)=>{
              if(resp.status===200){      
                setSnackbar({ children: 'Edicion realizada con éxito', severity: 'success' });
                setPromiseArguments(null);
                resolve(newRow);
              }
          })
        } catch (error) {
          setSnackbar({ children: "No se pudo realizar la petición", severity: 'error' });
          reject(oldRow);
          setPromiseArguments(null);
        }
      };
  
      const renderConfirmDialog = () => {
        if (!promiseArguments) {
          return null;
        }
    
        const { newRow, oldRow } = promiseArguments;
        const mutation = computeMutation(newRow, oldRow);
    
        return (
          <Dialog
            maxWidth="xs"
            TransitionProps={{ onEntered: handleEntered }}
            open={!!promiseArguments}
          >
            <DialogTitle>¿Esta seguro?</DialogTitle>
            <DialogContent dividers>
              {`Usted al presionar en 'Si' cambiará ${mutation}.`}
            </DialogContent>
            <DialogActions>
              <Button ref={noButtonRef} onClick={handleNo}>
                No
              </Button>
              <Button onClick={handleYes}>Yes</Button>
            </DialogActions>
          </Dialog>
        );
      };
    //-------- effects --------//
    useEffect(() =>{
        if(!(compraEditar===undefined)){
            let sumaCosto = (parseInt(costoUnitEditar)*parseInt(compraEditar.cantidad))+parseInt(costoEnvioEditar)
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.id==='inputCostoTotal'? input.value = sumaCosto: void 0)
            );
        }
    },[costoUnitEditar,costoEnvioEditar, compraEditar])

    useEffect(() =>{
        dispatchFetchCompras();
    },[dispatchFetchCompras])

    useEffect(() =>{
        setComprasState(comprasFiltroFlexible)
    },[textoBuscado,estadoBuscado,tipoBuscado,proveedorBuscado,compras,compras.loading])

    useEffect(() =>{
        if(costoBuscado1==='') setCostoBuscado1('00')
        else if (costoBuscado2==='') setCostoBuscado2('1000000')
        else if (fechaBuscada1==='') setFechaBuscada1('01-04-2015')
        else if (fechaBuscada2==='') setFechaBuscada2('01-04-2024')

    },[fechaBuscada1,fechaBuscada2,costoBuscado1,costoBuscado2])

    /**funciones, eventos click, botones */
    let confirmarEliminarCompra = (row)=>{
        setCompraAccion(row);
        setConfirmarElim(true);
    }

    let eliminarCompra = async() => {
        console.log("eliminarCompra",)
        var codigo = compraAccion.codigo;
        await axios.post(URL_ELIMINAR_COMPRA,
            {codigo})
            .then((resp)=>{
              if(resp.status===200){
                  showModalExito.current();
                  setConfirmarElim(false);
                  setTextoBuscado('');
                  dispatchFetchCompras();
              }
            })
            .catch((error)=>{
                showModalError.current();
                setConfirmarElim(false);
                setTextoBuscado('');
                dispatchFetchCompras();
            });
    }; 

    let actualizarTabla = () => {
        dispatchFetchCompras();
        setTextoBuscado('');
    }; 

    //-------- variables de archivo excel --------//
    let [isSelected,setIsSelected] = useState(false);

    let [archivoExcel,setArchivoExcel] = useState();
    let fileHandler = (event) => {
        let fileObj = event.target.files[0];
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
        if(err){
            console.log(err);            
        }
        else{
            if(validationExcelOC(resp)) {
                setArchivoExcel({
                    cols: resp.cols,
                    rows: resp.rows,
                    file: fileObj
                });
            }
        }
        });               
    }

    // Validación de las columnas en fichero excel
    let validationExcelOC = (resp) => {
        let cols = resp.cols;
        if (cols.length !== 11) {
            alert("El fichero cargado no tiene el formato correcto. Por favor, cargue un excel con el formato correcto.");
            return false;
        }
        return true;
    }

    useEffect(() =>{
        if(archivoExcel){
            setIsSelected(!isSelected);
        }
    },[archivoExcel])
    /** */
    /* Activadores de modales */
    const showModalArchivo = useRef(null);
    const showModalOC = useRef(null);
    const showModalActEstado = useRef(null);
    const showModalActCostos = useRef(null);
    const showModalExito = useRef(null);
    const showModalError = useRef(null);

    return (
        <div className='container'>
            {hasRoleAdmin(token) && 
            <div className='row'>
                <div className='col-12'>
                    <h5>CARGA MASIVA ORDENES DE COMPRA</h5>
                    <hr/>
                </div>
                <div className='col'>
                    <label htmlFor="formFile" className="form-label" >
                    Arrastre o seleccione un archivo.
                    </label>
                    <input className="form-control" type="file" name="file" id="fileInvoice" onChange={fileHandler.bind(this)} />
                    {archivoExcel?
                        <button className="btn btn-primary mt-1 mb-2" type="submit"  onClick={() => showModalArchivo.current()}>Pre-visualizar</button>       
                            :void 0
                    }
                </div>
                {isSelected ? (
                    <div className='col'>
                        <p>Nombre de archivo: {archivoExcel.file.name}</p>
                        <p>Tipo de archivo: {archivoExcel.file.type}</p>
                        <p>Tamaño en bytes bytes: {archivoExcel.file.size}</p>
                    </div>
                    ) : (
                    <div className='col mt-4'>
                        <Alert severity="info"> Seleccione un archivo para actualizar los estados de los pedidos.</Alert>
                    </div>
                )}
            </div>
            }
            <br/>
            <div className='row'>
                <div className='col'>
                    <Button variant="contained" color="success" onClick={()=>{showModalOC.current()}}>
                        Agregar OC manual<i className="fas fa-plus px-2"></i>
                    </Button>
                </div>
            </div>
            <br/>
            <br/>
            <div className='col-12'>
                    <h4>ORDENES DE COMPRA</h4>
                    <hr/>
                </div>
            {compras.loading? 
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status" style={{width:'3rem',height:'3rem'}} >
                    <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                :
                <div>
                <div className='row mb-2'>
                    <div className='col-3'>
                        <TextField size='medium' id="filled-basic" label="Buscar" variant="outlined" 
                            onChange={(event)=>setTextoBuscado(event.target.value)}
                        />
                    </div>
                    <div className='col-3'>
                        <FormControl sx={{ width: 190 }} size='medium'>
                            <InputLabel id="demo-multiple-chip-label">Estado</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={estadoBuscado}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                    </Box>
                                )}
                                onChange={handleChangeEstado}
                            >
                                {estados.map((name) => (
                                    <MenuItem
                                    key={name}
                                    value={name}
                                    >
                                    {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-3'>
                        <FormControl sx={{ width: 190 }}>
                            <InputLabel id="demo-multiple-chip-label">Tipo</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={tipoBuscado}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                    </Box>
                                )}
                                onChange={handleChangeTipo}
                            >
                                <MenuItem value={'OC'}>Orden de Compra</MenuItem>
                                <MenuItem value={'PE'}>Pedido Express</MenuItem>
                                <MenuItem value={'IM'}>Importación</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-3'>
                        <TextField id="filled-basic" label="Proveedor" variant="outlined" 
                            onChange={(event)=>setProveedorBuscado(event.target.value)}
                        />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-3'>
                        <Button variant="outlined" color="primary" onClick={()=>{actualizarTabla()}}>
                            Actualizar <ReplayIcon />
                        </Button>
                    </div>
                </div>
                </div>
            }
            <br/>

            <div style={{ height: 600, width: '100%' }}>
                {renderConfirmDialog()}
                <DataGrid
                    rows={comprasState}
                    columns={columns}
                    getRowId={(row)=>row.codigo}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10,20,50]}
                    checkboxSelection
                    disableSelectionOnClick
                    processRowUpdate={processRowUpdate}
                    isCellEditable={(params) => params.row.estado !== 'RECIBIDO' }
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{ Toolbar: GridToolbar }}
                />
            </div>
            {/*------- MODALES --------*/}
            {archivoExcel?
                <ModalArchivoOC showModal={showModalArchivo} data={archivoExcel.rows} columns={archivoExcel.cols} file={archivoExcel.file} token={token} />
                : void 0
            }
            <ModalIngresoOC showModal={showModalOC} titulo={'Ingreso manual de Orden de Compra'} token={token} />
            <ModalActEstado showModal={showModalActEstado} token={token} titulo={'Actualización manual de estado de OC'} />
            <ModalActCostos showModal={showModalActCostos} token={token} titulo={'Actualización manual costos de OC'}/>
            {/* MODALES */}
            <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Ingreso de productos exitoso!'} />
            <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error al subir archivo de productos a stock!'} />
            <Dialog
            maxWidth="xs"
            open={confirmarEliminar}
            >
            <DialogTitle>¿Esta seguro?</DialogTitle>
            <DialogContent dividers>
                {'Estas seguro?'}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setConfirmarElim(false)}>
                No
                </Button>
                <Button onClick={()=>eliminarCompra()}>Si</Button>
            </DialogActions>
            </Dialog>
            {/*------- snackbar --------*/}
            {!!snackbar && (
            <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
                <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    compras: state.compras,
});
  
const mapDispatchToProps = {
    dispatchFetchCompras: actions.fetchCompras,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeguimientoOC);
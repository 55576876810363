import React,{useEffect,useState} from 'react';
import { connect } from "react-redux";
import actions from "../../actions";
import { MDBInput } from 'mdb-react-ui-kit';
import { MDBBtn,MDBIcon } from 'mdb-react-ui-kit';
import {Paginacion,SortService} from '../../services/Services';

function AccionesDesarme({acciones,dispatchAcciones}) {
    let [accionesState,setAccionesState] = useState([])
    //----------- orden de tabla ----------------//
    let [sortBy, setSortBy] = useState("productName");
    let [sortOrder, setSortOrder] = useState("ASC"); //ASC or DESC
    //----------- paginacion de tabla ----------------//
    let [pageSize] = useState(10);
    let [pages, setPages] = useState([]);
    let [currentPageIndex, setCurrentPageIndex] = useState(0);
    let [accionesTabla, setAccionestabla] = useState([]);
    //------------VARIABLES DE FILTROS-------------------//
    let [codigoBuscado, setCodigoBuscado] = useState("");
    let [descripcionBuscada, setDescripcionBuscada] = useState("");
    let [accionBuscada, setAccionBuscada] = useState("");
    let [usuarioBuscado, setUsuarioBuscado] = useState("");
    let [fechaBuscada, setFechaBuscada] = useState("");

    //------------LOGICA DE FILTROS!!-------------------//
    let accionesFiltCodigo = accionesState.filter(elem => 
        elem.codigo.indexOf(codigoBuscado.toUpperCase()) >= 0
    );
    let accionesFiltDescripcion = accionesFiltCodigo.filter(elem => 
        elem.descripcion.indexOf(descripcionBuscada.toUpperCase()) >= 0
    );
    let accionesFiltAccion = accionesFiltDescripcion.filter(elem => 
        elem.accion.indexOf(accionBuscada.toUpperCase()) >= 0
    );
    let accionesFiltUsuario = accionesFiltAccion.filter(elem => 
        elem.usuario_accion.indexOf(usuarioBuscado) >= 0
    );
    let accionesFiltFecha = accionesFiltUsuario.filter(elem => 
        elem.fecha_accion.indexOf(fechaBuscada) >= 0
    );

    //when the user clicks on a column name to sort
    let onSortColumnNameClick = (event, columnName) => {
        event.preventDefault(); //avoid refresh
        setSortBy(columnName);
        let negatedSortOrder = sortOrder === "ASC" ? "DESC" : "ASC";
        setSortOrder(negatedSortOrder);
        setAccionesState(SortService.getSortedArray(acciones.data, columnName, negatedSortOrder))
        
    };

    useEffect(() =>{
        dispatchAcciones();
    },[dispatchAcciones])

    useEffect(() =>{
        setAccionesState(acciones.data)
    },[acciones.loading])

    useEffect(() =>{
        setCurrentPageIndex(0)
    },[])

    //Funcion de paginacion
    useEffect(() => {
        //calcula el numro de paginas y crea un objeto pagina
        setPages(Paginacion.getPagesArray(accionesFiltFecha, pageSize));
        //paging
        let pagedArray = Paginacion.getRowsByPageIndex(
            accionesFiltFecha,
            currentPageIndex,
            pageSize
        );
        //get sorted products
        setAccionestabla(pagedArray);
    }, [accionesState,acciones.loading,currentPageIndex,codigoBuscado,descripcionBuscada,accionBuscada,usuarioBuscado,fechaBuscada])
    

    let onPageIndexClicked = (clickedPageIndex) => {
        if (clickedPageIndex >= 0 && clickedPageIndex < pages.length)
        setCurrentPageIndex(clickedPageIndex);
    };

    let onRestablecerFiltClick = () => {
        setCodigoBuscado('')
        setDescripcionBuscada('')
        setAccionBuscada('')
        setUsuarioBuscado('')
        setFechaBuscada('')
    };
    
    //render column name
    let getColumnHeader = (columnName, displayName) => {
        return (
            <React.Fragment>
                <a
                href="/#"
                onClick={(event) => {
                    onSortColumnNameClick(event, columnName);
                }}
                >
                {displayName}
                </a>{" "}
                {sortBy === columnName && sortOrder === "ASC" ? (
                <i className="fa fa-sort-up"></i>
                ) : (
                ""
                )}
                {sortBy === columnName && sortOrder === "DESC" ? (
                <i className="fa fa-sort-down"></i>
                ) : (
                ""
                )}
            </React.Fragment>
        );
    };

    return (
        <div className='col'>
            <div className='container mb-4'>
                <h4>FILTROS DE BUSQUEDA</h4>
                <hr/>
                <div className='row'>
                    <div className='col'>
                        <MDBInput label='Codigo' id='form1' type='text' onChange= {(event) => {setCodigoBuscado(event.target.value)}} />
                    </div>
                    <div className='col'>
                        <MDBInput label='Descripcion' id='form1' type='text' onChange= {(event) => {setDescripcionBuscada(event.target.value)}} />
                    </div>
                    <div className='col'>
                        <select className="form-select" aria-label="Default select example" onChange={(event) => {setAccionBuscada(event.target.value)}}>
                            <option value="">TODO</option>
                            <option value="VENTA">VENTA</option>
                            <option value="GARANTIA">GARANTIA</option>
                            <option value="PIEZA DAÑADA">PIEZA DAÑADA</option>
                            <option value="DEVOLUCION">DEVOLUCION</option>
                        </select>
                    </div>
                    <div className='col'>
                        <MDBInput label='Usuario' id='form1' type='text' onChange= {(event) => {setUsuarioBuscado(event.target.value)}} />
                    </div>
                    <div className='col'>
                        <button type="button" className="btn btn-primary btn-sm"
                        onClick={() => {onRestablecerFiltClick()}}
                        >
                            restablecer Filtros
                        </button>
                    </div>
                </div>

                <div className='row mt-2'>
                    <div className='col-3'>
                    <label htmlFor="fechainput" className="form-label">Fecha</label>
                        <input type="date" className="form-control" id="fechainput" onChange={(event) => {setFechaBuscada(event.target.value.split('-').reverse().map(elem => elem[0]==='0'?elem[1]:elem).join('-'))}}/>
                    </div>
                </div>
            </div>

            {acciones.loading? 
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status" style={{width:'3rem',height:'3rem'}} >
                    <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                :
                <div>
                <div className='table-responsive'>
                    <MDBBtn outline onClick={()=>{dispatchAcciones()}} className='mb-2'> 
                        <MDBIcon fas icon="redo" />Actualizar listado
                    </MDBBtn>
                <table className="table table-striped table-sm">
                    <thead className='table-dark'>
                        <tr>
                            <th>{getColumnHeader("accion", "Accion")}</th>
                            <th>{getColumnHeader("fecha_accion", "Fecha")}</th>
                            <th>{getColumnHeader("hora_accion", "Hora")}</th>
                            <th>{getColumnHeader("usuario_accion", "Usuario")}</th>
                            <th>{getColumnHeader("operador", "Valor Accion")}</th>
                            <th>{getColumnHeader("codigo", "Codigo")}</th>
                            <th>{getColumnHeader("descripcion", "Descripcion")}</th>
                            <th>{getColumnHeader("auto_marca", "Marca Auto")}</th>
                            <th>{getColumnHeader("auto_carroceria", "Carroceria Auto")}</th>
                            <th>{getColumnHeader("auto_motor", "Motor Auto")}</th>
                            <th>{getColumnHeader("num_parte", "Num Parte")}</th>
                            <th>{getColumnHeader("num_auto", "Num Auto")}</th>
                            <th>{getColumnHeader("cantidad", "Cantidad")}</th>
                            <th>{getColumnHeader("observacion", "Observacion")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accionesTabla.map((elem,index)=>{
                            return(
                                <tr key={index} 
                                    className={elem.accion ==='DEVOLUCION'?'table-warning':elem.accion ==='VENTA'?'table-success':void 0}
                                >
                                    <td>{elem.accion}</td>
                                    <td>{elem.fecha_accion}</td>
                                    <td>{elem.hora_accion}</td>
                                    <td>{elem.usuario_accion}</td>
                                    <td>{elem.operador}</td>
                                    <td>{elem.codigo}</td>
                                    <td style={{fontSize:'0.8rem', padding:'3px 0px 0px 0px', textAlign:'center'}}>{elem.descripcion}</td>
                                    <td>{elem.auto_marca}</td>
                                    <td>{elem.auto_carroceria}</td>
                                    <td>{elem.auto_motor}</td>
                                    <td>{elem.num_parte}</td>
                                    <td>{elem.num_auto}</td>
                                    <td>{elem.cantidad}</td>
                                    <td 
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            margin: '0',
                                            padding: '0',
                                            overflow: 'auto'
                                        }}
                                    >
                                        {elem.observacion}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </div>
                <ul className="pagination justify-content-center mt-1">
                    <li
                        className="page-item"
                        onClick={() => {
                            onPageIndexClicked(currentPageIndex - 1);
                        }}
                    >
                        <a
                        className="page-link bg-primary text-white"
                        href="/#"
                        onClick={(event) => {
                            event.preventDefault();
                        }}
                        >
                        Prev
                        </a>
                    </li>
                    {pages.map((page) => (
                        <li
                        key={page.pageIndex}
                        className={
                            currentPageIndex === page.pageIndex
                            ? "page-item active"
                            : "page-item"
                        }
                        onClick={() => {
                            onPageIndexClicked(page.pageIndex);
                        }}
                        >
                        <a
                            className="page-link"
                            href="/#"
                            onClick={(event) => {
                            event.preventDefault();
                            }}
                        >
                            {page.pageIndex + 1}
                        </a>
                        </li>
                    ))}

                    <li
                        className="page-item"
                        onClick={() => {
                            onPageIndexClicked(currentPageIndex + 1);
                        }}
                    >
                        <a
                        className="page-link bg-primary text-white"
                        href="/#"
                        onClick={(event) => {
                            event.preventDefault();
                        }}
                        >
                        Next
                        </a>
                        </li>
                    </ul>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    acciones: state.acciones,
});
  
const mapDispatchToProps = {
    dispatchAcciones: actions.fetchAccionesDesarme,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccionesDesarme);
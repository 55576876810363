import React, { useState } from 'react'
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import {API_HOST_PY} from "../../config";
import { showLoader } from '../../services/utils/LoaderService';

async function loginUser(credentials) {
    const endpoint = `${API_HOST_PY}/user/login`;
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    });
}

export default function Login({ setToken }) {
    let [mensajeError,setMensajeError] = useState('');
    let [error,setError] = useState(false);
    let [loading,setLoading] = useState(false);
    let [usuario,setUsuario] = useState('');
    let [clave ,setClave] = useState('');

    const handleSubmit = async e => {
        setLoading(true);
        e.preventDefault();
        try {
            const resp = await loginUser({
                usuario,
                clave
            });
            if (resp.status === 401) {
                setMensajeError('Clave o Usuario incorrecto.');
                setError(true);
            } else if (!resp.ok) {
                setMensajeError('Error en la solicitud');
                setError(true);
            } else {
                const token = await resp.json();
                setToken(token);
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
            setMensajeError('Error en la solicitud');
            setError(true);
        } finally {
            setLoading(false);
        }
    }
       
    return (
        <React.Fragment>
        {showLoader(loading)}
        <div className='container shadow p-5' style={{marginTop:'150px'}} >
            <div className='row mx-auto '>
                <div className='col-12'>
                    <h1>LOGIN AUTOFIX EXPRESS GESTION ERP 2023</h1>
                </div>
                <form className='row' onSubmit={handleSubmit}>
                    <div className='col-12 mb-3'>
                        <TextField id="outlined-basic" label="Usuario" variant="outlined" fullWidth 
                            onChange={e => setUsuario(e.target.value)} 
                        />
                    </div>
                    <div className='col-12 mb-3'>
                        <TextField id="outlined-basic" label="Clave" variant="outlined" type="password" fullWidth 
                            onChange={e => setClave(e.target.value)}
                        />
                    </div>
                    <div className='col'>
                        <button type="submit" className='btn btn-primary'>Entrar</button>
                    </div>
                    {error?
                        <div className='col'>
                            <p className='text-danger'>{mensajeError}</p>
                        </div>
                        :void 0
                    }

                </form>
            </div>
        </div>
        <p className='container'>VERSION 1.0.2</p>
        </React.Fragment>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}
import React, { useState, useEffect } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { DataGrid,useGridApiContext } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
/***/


function IngresoUbicacionPaso2({
    token,
    comprasSelec,
    getSeleccion,
    lemans
  }){

  const bodegasSelect = [
    'BODEGA AUTOFIX',
    'BODEGA ACOPIO',
    'BODEGA 24LEMANS',
  ];

  const filasSelect = [
    "F1",
    "F2",
    "F3",
    "F4",
    "F5",
  ];

  const cajasSelect = [
    "SIN CAJA",
    "CAJA 1",
    "CAJA 2",
    "CAJA 3",
    "CAJA 4",
    "CAJA 5",
  ];

    /*data grid */
const columns = [
  {
    field: 'codigo_dess',
    headerName: 'Codigo DESS',
    width: 150,
  },
  {
    field: 'num_parte_prov',
    headerName: 'Num Parte',
    width: 110,
  },
  {
    field: 'descripcion',
    headerName: 'Descripcion',
    sortable: false,
    width: 160
  },
  {
    field: 'cantidad',
    headerName: 'Cantidad Recibida',
    sortable: false,
    width: 150,
    editable:true,
    type: 'number',
    renderEditCell: RenderEditCantidadCell,
    renderCell: (params) => (
      <div>
      {params.row.errorMaximo&&     
        <Tooltip title="Cantidad excedente!">
          <IconButton>
            <ErrorOutlineIcon sx={{ color: 'red' }}/>
          </IconButton>
        </Tooltip>
      }
      {params.row.errorMinimo&&     
        <Tooltip title="Cantidad faltante!">
          <IconButton>
            <ErrorOutlineIcon color='info'/>
          </IconButton>
        </Tooltip>
      }
      {params.row.cantidad}
      <IconButton disabled={params.row.errorMaximo || params.row.errorMinimo || params.row.cantidad===1} onClick={()=>handleAddRow(params)}>
        <HorizontalSplitIcon />
      </IconButton>
      </div>
    ),
    preProcessEditCellProps: procesarEdicion
  },
  {
    field: 'marca_repuesto',
    headerName: 'Marca Producto',
    sortable: false,
    width: 150,
    editable:true,
    type: 'string',
    preProcessEditCellProps:procesarEdicionMarcaRepuesto
  },
  {
    field: 'bodega',
    headerName: 'Bodega',
    sortable: false,
    width: 190,
    editable:true,
    type: 'singleSelect',
    valueOptions: bodegasSelect,
    preProcessEditCellProps: procesarEdicionBodega
  },
  {
    field: 'rack',
    headerName: 'Rack',
    sortable: false,
    width: 150,
    editable:true,
    type: 'singleSelect',
    preProcessEditCellProps: procesarEdicionRack,
    renderEditCell:RenderEditRackCell,
    valueSetter:setRack,
  },
  {
    field: 'fila',
    headerName: 'Fila',
    sortable: false,
    width: 150,
    editable:true,
    type: 'singleSelect',
    valueOptions: filasSelect,
    preProcessEditCellProps: procesarEdicionFila
  },
  {
    field: 'caja',
    headerName: 'Caja',
    sortable: false,
    width: 150,
    editable:true,
    type: 'singleSelect',
    valueOptions: cajasSelect,
    preProcessEditCellProps: procesarEdicionCaja
  },
  {
    field: 'Accion',
    headerName: 'Ubicar',
    sortable: false,
    width: 150,
    renderCell: (elem) => (
      <Button
      variant="contained"
      color="success"
      disabled={
        elem.row.errorMaximo || elem.row.errorMinimo || elem.row.enableUbicationInput ||
        !elem.row.bodega || !elem.row.rack || !elem.row.fila || !elem.row.caja 
      }
      endIcon={<AddBoxIcon />}
      onClick={() => {
        onClickAddToStock(elem.row);
      }}
    >
      Confirmar
    </Button>
    ),
  }
];

  function procesarEdicionBodega(params){
    const compraEditada = comprasSelecState.filter((elem)=> elem.id === params.row.id);
    compraEditada.forEach(function(elem){
      elem.bodega = params.props.value
    })
    setComprasSelecState([...comprasSelecState]);
    return { ...params.props };
  }

  function procesarEdicionRack(params){
    const compraEditada = comprasSelecState.filter((elem)=> elem.id === params.row.id);
    compraEditada.forEach(function(elem){
      if(params.props.value){
        if(params.props.value.toString().includes('R')){
          elem.rack = '';
        }else {
          elem.rack = 'R' + params.props.value
        }
      }else{
        elem.rack = '';
      }
    })
    setComprasSelecState([...comprasSelecState]);
    return { ...params.props };
  }

  function procesarEdicionFila(params){
    const compraEditada = comprasSelecState.filter((elem)=> elem.id === params.row.id);
    compraEditada.forEach(function(elem){
      elem.fila = params.props.value
    })
    setComprasSelecState([...comprasSelecState]);
    return { ...params.props };
  }

  function procesarEdicionCaja(params){
    const compraEditada = comprasSelecState.filter((elem)=> elem.id === params.row.id);
    compraEditada.forEach(function(elem){
      elem.caja = params.props.value
    })
    setComprasSelecState([...comprasSelecState]);
    return { ...params.props };
  }

  function procesarEdicionMarcaRepuesto(params){
    const compraEditada = comprasSelecState.filter((elem)=> elem.id === params.row.id);
    compraEditada.forEach(function(elem){
      elem.marca_repuesto = params.props.value
      elem.marca_ingresada = true
    })
    setComprasSelecState([...comprasSelecState]);
    return { ...params.props };
  }

  function setRack(params) {
    //console.log("params.value",params.value.toString().includes('R'))
    const rackAux = 'R' + params.value;
    return { ...params.row, rackAux };
  }

  function RenderEditRackCell(props){
    const { id, field } = props;
    const apiRef = useGridApiContext();
    const handleChange = (event) => {
      const item = comprasSelecState.find((item) => item.id === id);
      item[field] = 'R' + event.target.value;
      apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    }; 

    return(
      <TextField type={'number'} defaultValue={props.value} onChange={handleChange}/>
    );
  }

  function RenderEditCantidadCell(props){
    const { id, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = (event) => {
      const item = comprasSelecState.find((item) => item.id === id);
      item[field] = event.target.value;
      apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    }; 

    return(
      <TextField type={'number'} defaultValue={props.value} onChange={handleChange}/>
    );
  }

  function procesarEdicion(params)  {
    const arregloCompras = comprasSelecState.filter((elem)=> elem.codigo===params.row.codigo && elem.id !== params.row.id)
    const arregloComprasCantidades = arregloCompras.map((elem)=> parseInt(elem.cantidad));
    const suma = arregloComprasCantidades.reduce((a, b) => a + b, 0);
    const cantidadValidar =  suma + parseInt(params.props.value)
    const errorMaximo = cantidadValidar > params.row.cantidad_recibida_orig ;
    const errorMinimo = cantidadValidar < params.row.cantidad_recibida_orig ;
    arregloCompras.forEach(function(elem){
      elem.errorMaximo = errorMaximo;
      elem.errorMinimo = errorMinimo;
    })
    params.row.errorMaximo = errorMaximo;
    params.row.errorMinimo = errorMinimo;
    setComprasSelecState([...comprasSelecState])
    return { ...params.props };
  }

  const handleAddRow = (params) => {
    const auxElem = {...params.row}
    const auxElem2 = {...params.row}
    /* Guardamos la cantidad recibida original de la compra entera,
     * para validar que la suma de las filas hijas no superen la cantidad original 
     */
    auxElem.cantidad = Math.floor(params.value/2) + (params.value%2)
    auxElem2.cantidad = Math.floor(params.value/2)
    params.value = Math.floor(params.value/2);
    let comprasAux = [...comprasSelecState];
    const index = comprasAux.map(object => object.id).indexOf(params.row.id);
    comprasAux.splice(index,1,auxElem,auxElem2);
    setComprasSelecState(comprasAux.map((row,index)=>({...row,id:index})))
  };

  //-------- state -------//
  let [pageSize,setPageSize] = useState(10);
  let [ubicaciones,setUbicaciones] = useState([]);
  let [comprasSelecState, setComprasSelecState] = useState([]);
/* Eventos */
  const onClickAddToStock = (elem) => {
    console.log(elem)
    elem.enableUbicationInput = true;
    setUbicaciones((prev)=>([...prev,elem]))
  };

  //--------effects --------
  useEffect(() => {
    setComprasSelecState(comprasSelec.map((elem,index)=>(
      {...elem,id:index, 
        cantidad_recibida_orig:elem.cantidad,
        errorMaximo:false,
        errorMinimo:false
      }
      )));
    console.log(comprasSelec)
  }, [comprasSelec]);
  
  /**funciones */
  let getSeleccionInv = ()=>{
    return(ubicaciones);
  }
  /**effects */
  useEffect(() => {
    getSeleccion.current = getSeleccionInv
  }, [])

  return (
    <React.Fragment>
      <div style={{ height: 550, width: '100%' }}>
        <DataGrid
          rows={comprasSelecState}
          columns={columns}
          getRowId={(row)=>row.id}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10,20,50]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
  );
}

export default IngresoUbicacionPaso2;
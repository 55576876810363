import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ConstructionIcon from "@mui/icons-material/Construction";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import LogoDevIcon from "@mui/icons-material/LogoDev";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LanguageIcon from "@mui/icons-material/Language";
import StartIcon from "@mui/icons-material/Start";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Link } from "react-router-dom";

import {
  getUserByToken,
  hasRoleContentManager,
  isBloger,
} from "../../core/auth/auth";

const logOut = () => {
  sessionStorage.removeItem("token");
  window.location.reload();
};

const drawerWidth = 250;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const ListItemSideBar = (props) => {
  const { textColor } =
    props.textColor != null ? props : { textColor: "#1976d2" };
  const { button } = props.button != null ? props : { button: false };
  const { onClick } = props.onClick != null ? props : { onClick: () => {} };
  return (
    <ListItem
      style={props.style}
      button={button}
      key={props.text}
      onClick={onClick}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText
        disableTypography
        primary={props.text}
        style={{
          fontWeight: "bold",
          fontFamily: "Segoe UI Emoji",
          fontSize: "18px",
          color: textColor,
        }}
      />
    </ListItem>
  );
};

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const { token } = props;
  const [open, setOpen] = React.useState(false);
  const { children } = props;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{
          background: "#1976d2",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Link to={"/"} style={{ color: "#FFFFFF" }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ marginRight: "10px" }}
            >
              AutoFix Express Gestión
            </Typography>
          </Link>
          <KeyboardReturnIcon />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <ListItemSideBar
          text={`Usuario: ${getUserByToken(token)}`}
          icon={<AccountCircleIcon />}
        />
        <Divider />
        <List>
          <Link hidden={isBloger(token)} to="/">
            <ListItemSideBar
              button={true}
              onClick={handleDrawerClose}
              text="Inicio"
              icon={<StartIcon />}
            />
          </Link>
          <Link hidden={isBloger(token)} to="/desarme">
            <ListItemSideBar
              button={true}
              onClick={handleDrawerClose}
              text="Desarme"
              icon={<ConstructionIcon />}
            />
          </Link>
          <Link hidden={isBloger(token)} to="/ordenescompra">
            <ListItemSideBar
              button={true}
              onClick={handleDrawerClose}
              text="Ordenes de Compra / Importaciones"
              icon={<AddShoppingCartIcon />}
            />
          </Link>
          <Link hidden={isBloger(token)} to="/stock">
            <ListItemSideBar
              button={true}
              onClick={handleDrawerClose}
              text="Gestión Stock Autofix"
              icon={<Inventory2Icon />}
            />
          </Link>
          <Link hidden={isBloger(token)} to="/stock24lemans">
            <ListItemSideBar
              button={true}
              onClick={handleDrawerClose}
              text="Gestión Stock 24Lemans"
              icon={<Inventory2Icon />}
            />
          </Link>
          <Link hidden={isBloger(token)} to="/soporte">
            <ListItemSideBar
              button={true}
              onClick={handleDrawerClose}
              text="Soporte Técnico"
              icon={<SupportAgentIcon />}
            />
          </Link>
        </List>

        <Divider />
        <List>
          <ListItemSideBar
            button={true}
            onClick={logOut}
            text="Salir"
            icon={<ExitToAppIcon />}
          />
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}

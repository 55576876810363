import * as actionTypes from "../constants/action-types";
import axios from "axios";
import {
  URL_FETCH_COMPRAS,
  URL_FETCH_ULTIMAS_COMPRAS,
  URL_FETCH_ULTIMAS_COMPRAS_INGRESADAS,
  URL_FETCH_SOLIC_SIN_MATCH,
  URL_FETCH_PROVEEDORES,
  URL_FETCH_ACT_COMPRAS,
  URL_FETCH_PEDIDOS_DUMP
} from "../config";

export const fetchCompras = (props) => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_COMPRAS});
  try
  {
    var response = await axios.get(URL_FETCH_COMPRAS);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_COMPRAS_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_COMPRAS_ERROR,
      payload: err
    });
  }
};

export const fetchUtlActCompras = (props) => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_ULT_ACTS_COMPRAS});
  try
  {
    var response = await axios.get(`${URL_FETCH_ULTIMAS_COMPRAS}/${props}`);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_ULT_ACTS_COMPRAS_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_ULT_ACTS_COMPRAS_ERROR,
      payload: err
    });
  }
};

export const fecthSolicSinMatch = (props) => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_SOLIC_SIN_MATCH});
  try
  {
    var response = await axios.get(`${URL_FETCH_SOLIC_SIN_MATCH}/${props}`);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_SOLIC_SIN_MATCH_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_SOLIC_SIN_MATCH_ERROR,
      payload: err
    });
  }
};

export const fetchUltComprasIng = (props) => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_ULT_COMPRAS_ING});
  try
  {
    var response = await axios.get(`${URL_FETCH_ULTIMAS_COMPRAS_INGRESADAS}/${props}`);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_ULT_COMPRAS_ING_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_ULT_COMPRAS_ING_ERROR,
      payload: err
    });
  }
};

export const fetchProveedores = () => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_PROVEEDORES});
  try
  {
    var response = await axios.get(URL_FETCH_PROVEEDORES);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PROVEEDORES_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PROVEEDORES_ERROR,
      payload: err
    });
  }
};

export const fetchActCompras = () => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_ACT_COMPRAS});
  try
  {
    var response = await axios.get(URL_FETCH_ACT_COMPRAS);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_ACT_COMPRAS_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_ACT_COMPRAS_ERROR,
      payload: err
    });
  }
};

export const fetchPedidosDump = () => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_PEDIDOS_DUMP});
  try
  {
    var response = await axios.get(URL_FETCH_PEDIDOS_DUMP);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PEDIDOS_DUMP_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PEDIDOS_DUMP_ERROR,
      payload: err
    });
  }
};
import { hasRoleAdmin } from '../../core/auth/auth';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';

export const Columnas = {
    getColumnasTabla: (token,onClickSeleccionPieza, eliminarPieza) => {
        const columns = [
            {
              field: 'accion',
              headerName: 'Acción',
              width: 70,
              disableClickEventBubbling: true,
              renderCell: (params) => {
                return (
                  <IconButton
                    color="primary" aria-label="add to shopping cart"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      // Manejar la acción del botón aquí, por ejemplo, mostrar un diálogo de confirmación
                      onClickSeleccionPieza(params.row)
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                );
              },
            },
            {
              field: 'codigo',
              headerName: 'Codigo',
              width: 150,
              editable:hasRoleAdmin(token)
            },
            {
              field: 'descripcion',
              headerName: 'Descripcion',
              width: 150,
              editable:hasRoleAdmin(token)
            },
            {
              field: 'ubicacion',
              headerName: 'Ubicacion',
              width: 150,
              editable:hasRoleAdmin(token)
            },
            {
              field: 'caja',
              headerName: 'Caja',
              width: 150,
              editable:hasRoleAdmin(token)
            },
            {
              field: 'auto_marca',
              headerName: 'Marca Auto',
              width: 150,
              editable:hasRoleAdmin(token)
            },
            {
              field: 'auto_carroceria',
              headerName: 'Carroceria Auto',
              width: 150,
              editable:hasRoleAdmin(token)
            },
            {
              field: 'auto_motor',
              headerName: 'Motor Auto',
              width: 110,
            },
            {
              field: 'num_parte',
              headerName: 'Num Parte',
              width: 350,
              sortable:true,
              editable:hasRoleAdmin(token),
            },
            {
              field: 'num_auto',
              headerName: 'N° Auto',
              sortable: false,
              width: 150,
              editable:hasRoleAdmin(token)
            },
            {
              field: 'cantidad',
              headerName: 'Cantidad',
              sortable: true,
              width: 150,
              editable:hasRoleAdmin(token)
            },
            {
              field: 'usuario_ingreso',
              headerName: 'Ingresada por',
              sortable: false,
              width: 150,
              editable:hasRoleAdmin(token)
            },
            {
              field: 'actions',
              type: 'actions',
              getActions: (params) => [
                <GridActionsCellItem  icon={<DeleteIcon />} 
                  label="Delete" 
                  onClick={()=>eliminarPieza(params.row)}
                  disabled={!hasRoleAdmin(token)}
                  />,
              ]
          }
          ];
      return columns;
    },

    getColumnasTablaPiezasSelec: (token,onClickSeleccionPieza) => {
      const columns = [
          {
            field: 'accion',
            headerName: 'Acción',
            width: 70,
            disableClickEventBubbling: true,
            renderCell: (params) => {
              return (
                <IconButton
                  color="primary" aria-label="add to shopping cart"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    // Manejar la acción del botón aquí, por ejemplo, mostrar un diálogo de confirmación
                    onClickSeleccionPieza(params.row)
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              );
            },
          },
          {
            field: 'codigo',
            headerName: 'Codigo',
            width: 150,
          },
          {
              field: 'descripcion',
              headerName: 'Descripcion',
              width: 150,
          },
          {
              field: 'ubicacion',
              headerName: 'Ubicacion',
              width: 150,
          },
          {
            field: 'caja',
            headerName: 'Caja',
            width: 150,
          },
          {
              field: 'auto_marca',
              headerName: 'Marca Auto',
              width: 150,
          },
          {
            field: 'auto_carroceria',
            headerName: 'Carroceria Auto',
            width: 150,
            editable:hasRoleAdmin(token)
          },
          {
            field: 'auto_motor',
            headerName: 'Motor Auto',
            width: 110,
          },
          {
            field: 'num_parte',
            headerName: 'Num Parte',
            width: 350,
            sortable:true,
            editable:hasRoleAdmin(token),
          },
          {
              field: 'num_auto',
              headerName: 'N° Auto',
              sortable: false,
              width: 150,
              editable:hasRoleAdmin(token)
          },
          {
            field: 'cantidad',
            headerName: 'Cantidad',
            sortable: true,
            width: 150,
            editable:true
          },
          {
              field: 'usuario_ingreso',
              headerName: 'Ingresada por',
              sortable: false,
              width: 150,
              editable:hasRoleAdmin(token)
          }
        ];
    return columns;
  },

  getColumnasTablaUltimasMod: (token,onClickSeleccionPieza) => {
    const columns = [
        {
          field: 'codigo',
          headerName: 'Codigo',
          width: 150,
        },
        {
            field: 'descripcion',
            headerName: 'Descripcion',
            width: 150,
        },
        {
            field: 'ubicacion',
            headerName: 'Ubicacion',
            width: 150,
        },
        {
          field: 'caja',
          headerName: 'Caja',
          width: 150,
        },
        {
            field: 'auto_marca',
            headerName: 'Marca Auto',
            width: 150,
        },
        {
          field: 'auto_carroceria',
          headerName: 'Carroceria Auto',
          width: 150,
          editable:hasRoleAdmin(token)
        },
        {
          field: 'auto_motor',
          headerName: 'Motor Auto',
          width: 110,
        },
        {
          field: 'num_parte',
          headerName: 'Num Parte',
          width: 350,
          sortable:true,
          editable:hasRoleAdmin(token),
        },
        {
            field: 'num_auto',
            headerName: 'N° Auto',
            sortable: false,
            width: 150,
            editable:hasRoleAdmin(token)
        },
        {
          field: 'cantidad',
          headerName: 'Cantidad',
          sortable: true,
          width: 150,
          editable:true
        },
        {
            field: 'usuario_ingreso',
            headerName: 'Ingresada por',
            sortable: false,
            width: 150,
            editable:hasRoleAdmin(token)
        }
      ];
    return columns;
  }
}
import React,{useEffect,useState, useRef, useCallback} from 'react';
import { connect } from "react-redux";
import {Columnas} from "../../../services/Desarme/ColumnasItems";
import { DataGrid,GridToolbar} from '@mui/x-data-grid';
import actions from "../../../actions";
import "./ItemsDesarme.css"
import Button from "@mui/material/Button";
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import axios from "axios";
import {
    URL_EDIT_PIEZA,
    URL_ACCION_PIEZA,
    URL_DELETE_PIEZA,
    URL_FETCH_ULTIMAS_MODIFICACIONES
} from '../../../config';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ModalExito from '../../Modales/ModalExito';
import ModalError from '../../Modales/ModalError';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function ItemsDesarme({token,piezas,dispatchPiezasDesarme}){
    const options = ['Venta', 'Garantía', 'Pieza Dañada', 'Devolución'];

    /*state*/
    let [pageSize,setPageSize] = useState(10);
    let [piezasSeleccionadas,setPiezasSeleccionadas] = useState([]);
    let [numVentaNueva,setNumVentaNueva] = useState('');
    let [alertaNumVenta,setAlertaNumVenta] = useState(false);
    let [obsAccion,setObsAccion] = useState('');
    let [ultimasMod,setUltimasMod] = useState([]);
    let [accionRealizar,setAccionRealizar] = useState('VENTA');
    let [piezasState, setPiezasState] = useState([]);
    let [textoBuscado,setTextoBuscado] = useState('');

    /*SPLIT BUTTON */
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
  
    /*Effects */
    useEffect(() =>{
        dispatchPiezasDesarme();
        console.log(piezas)
    },[dispatchPiezasDesarme])

    /*Metodos*/
    const handleClick = () => {
        console.info(`You clicked ${options[selectedIndex]}`);
        setAccionRealizar(options[selectedIndex]);
        realizarAccion();
      };
  
    let realizarAccion = async() => {
        if(!numVentaNueva===''){
            var accion = accionRealizar
            var num_venta = numVentaNueva
            await axios.post(URL_ACCION_PIEZA,
                {piezasSeleccionadas,accion,obsAccion,token,num_venta})
                .then(setTimeout(() => { dispatchPiezasDesarme();setPiezasSeleccionadas([]); }, 1000))
                .then((resp)=>{
                    if(resp.status===200){
                        getUltModificaciones(resp.data);
                        showModalExito.current();
                        handleClose();
                    }
                  })
                  .then(setTimeout(() => { dispatchPiezasDesarme();setPiezasSeleccionadas([]); }, 1000))
                  .catch((error)=>{
                      showModalError.current();
                      handleClose();
                  });
                setNumVentaNueva('')
                setAlertaNumVenta(false)
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
            );
        }else {
            setAlertaNumVenta(true)
        }
    }; 

    let getUltModificaciones = async(horario) => {
        var ultimasModResponse = await axios.post(URL_FETCH_ULTIMAS_MODIFICACIONES, {horario});
        setUltimasMod(ultimasModResponse.data);
    }; 

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        realizarAccion();
    };

    const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let onClickSeleccionPieza = (pieza) => {
        let auxPieza = { ...pieza, cantidad: 1, cantOrig: pieza.cantidad };
        setPiezasSeleccionadas([...piezasSeleccionadas, auxPieza]);
    };

    let onClickQuitarSelecPieza = (pieza) => {
        let nuevoArray = piezasSeleccionadas.filter(elem => 
            elem.codigo != pieza.codigo
        );    
        setPiezasSeleccionadas(nuevoArray)
    };

    let eliminarPieza = (pieza) => {
        axios.get(`${URL_DELETE_PIEZA}/${pieza.id}`)
            .then((resp)=>{
                if(resp.status===200){
                    dispatchPiezasDesarme()
                    showModalExito.current();
                    handleClose();
                }
            })
            .catch((error)=>{
                showModalError.current();
                handleClose();
            });
    };

    /**
     * edicion
     */
    let [promiseArguments, setPromiseArguments] = useState(null);
    let noButtonRef = useRef(null);
    let [snackbar, setSnackbar] = useState(null);

    function computeMutation(newRow, oldRow) {
        if (newRow.codigo !== oldRow.codigo) {
          return `codigo de '${oldRow.codigo}' a '${newRow.codigo}'`;
        }
        if (newRow.descripcion !== oldRow.descripcion) {
          return `descripcion de '${oldRow.descripcion}' a '${newRow.descripcion}'`;
        }
        if (newRow.cantidad !== oldRow.cantidad) {
          return `cantidad de '${oldRow.cantidad}' a '${newRow.cantidad}'`;
        }
        if (newRow.num_auto !== oldRow.num_auto) {
          return `N° Auto de '${oldRow.num_auto}' a '${newRow.num_auto}'`;
        }
        if (newRow.caja !== oldRow.caja) {
            return `caja de '${oldRow.caja}' a '${newRow.caja}'`;
        }
        if (newRow.ubicacion !== oldRow.ubicacion) {
            return `ubicacion de '${oldRow.ubicacion}' a '${newRow.ubicacion}'`;
        }
        return null;
    }

    const handleEntered = () => {
        // The `autoFocus` is not used because, if used, the same Enter that saves
        // the cell triggers "No". Instead, we manually focus the "No" button once
        // the dialog is fully open.
        // noButtonRef.current?.focus();
    }; 

    const processRowUpdate = useCallback(
        (newRow, oldRow) =>
        //console.log(newRow, oldRow)
            new Promise((resolve, reject) => {
            const mutation = computeMutation(newRow, oldRow);
            if (mutation) {
                // Save the arguments to resolve or reject the promise later
                setPromiseArguments({ resolve, reject, newRow, oldRow });
            } else {
                resolve(oldRow); // Nothing was changed
            }
            }),
        [],  
    );

    const handleNo = () => {
        const { oldRow, resolve } = promiseArguments;
        resolve(oldRow); // Resolve with the old row to not update the internal state
        setPromiseArguments(null);
    };

    const handleYes = async () => {
        const { newRow, oldRow, reject, resolve } = promiseArguments;
        try {
            // Make the HTTP request to save in the backend
            //const response = await mutateRow(newRow);
            await axios.post(URL_EDIT_PIEZA,
            {piezaEditar: newRow,token,piezaOrig:oldRow})
            .then((resp)=>{
                if(resp.status===200){      
                setSnackbar({ children: 'Edicion realizada con éxito', severity: 'success' });
                setPromiseArguments(null);
                resolve(newRow);
                }
            })
        } catch (error) {
            setSnackbar({ children: "No se pudo realizar la petición", severity: 'error' });
            reject(oldRow);
            setPromiseArguments(null);
        }
    };
  
    const renderConfirmDialog = () => {
        if (!promiseArguments) {
            return null;
        }

        const { newRow, oldRow } = promiseArguments;
        const mutation = computeMutation(newRow, oldRow);

        return (
            <Dialog
            maxWidth="xs"
            TransitionProps={{ onEntered: handleEntered }}
            open={!!promiseArguments}
            >
            <DialogTitle>¿Esta seguro?</DialogTitle>
            <DialogContent dividers>
                {`Usted al presionar en 'Si' cambiará ${mutation}.`}
            </DialogContent>
            <DialogActions>
                <Button ref={noButtonRef} onClick={handleNo}>
                No
                </Button>
                <Button onClick={handleYes}>Yes</Button>
            </DialogActions>
            </Dialog>
        );
    };

    let piezasFiltro = piezas.data.filter(pieza => {
        var textoTotal = JSON.stringify(pieza)
        var count = -1
        for(let i=0; i<textoBuscado.split(' ').length;i++){
            if(textoTotal.indexOf(textoBuscado.toUpperCase().split(' ')[i]) >= 0){
                count ++;
            }
        }   
        if(count >= 0 && count===textoBuscado.split(' ').length-1){
            return true
        }else {
            return false
        }
    });

    useEffect(() =>{
        setPiezasState(piezasFiltro)
    },[textoBuscado, piezas])

    /**
     * modales
     */
    const showModalExito = useRef(null);
    const showModalError = useRef(null);
    
    return (
        <div>
            <div>
                <div className='container mb-2 g-0'>
                    <div className='row'>
                        <h4 className='col'>ULTIMAS MODIFICACIONES</h4>
                        <div className='col'>
                        <Button variant="outlined" color="primary" onClick={()=>{setUltimasMod([])}} >
                            Limpiar Tabla <CleaningServicesIcon />
                        </Button>  
                        </div>
                    </div>
                </div>
                {ultimasMod.length === 0? 
                    <span>no se ha modificado nada ultimamente...</span>
                    :
                    <div style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={ultimasMod}
                            columns={Columnas.getColumnasTablaUltimasMod(token,onClickQuitarSelecPieza)}
                            getRowId={(row)=>row.id}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10,20,50]}
                            disableSelectionOnClick
                            isCellEditable={(params) => params.row.estado !== 'RECIBIDO' }
                            experimentalFeatures={{ newEditingApi: true }}
                            components={{ Toolbar: GridToolbar }}
                            getRowClassName={(params) =>
                                piezasSeleccionadas.find( pieza => pieza.codigo === params.row.codigo && pieza.descripcion === params.row.descripcion )?'select-row':
                                params.row.cantidad===0?'agotada-row': ""
                            }
                        />
                    </div>
                }
            </div>
            <hr></hr>
            <div>
                <h4>ACCIONES</h4>
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={piezasSeleccionadas}
                        columns={Columnas.getColumnasTablaPiezasSelec(token,onClickQuitarSelecPieza)}
                        getRowId={(row)=>row.id}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10,20,50]}
                        disableSelectionOnClick
                        isCellEditable={(params) => params.row.estado !== 'RECIBIDO' }
                        experimentalFeatures={{ newEditingApi: true }}
                        components={{ Toolbar: GridToolbar }}
                        getRowClassName={(params) =>
                            params.row.cantidad === 0
                            ? "agotada-row"
                            : ""
                        }
                    />
                </div>
                <div className='row my-4'>
                    <div className='col'>
                        <h4>Acciones Masivas</h4>
                    </div>
                    <div className='col'>
                        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                            <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                            <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            >
                            <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Popper
                            sx={{
                            zIndex: 1,
                            }}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                        key={option}
                                        disabled={index === 2}
                                        selected={index === selectedIndex}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                        {option}
                                        </MenuItem>
                                    ))}
                                    </MenuList>
                                </ClickAwayListener>
                                </Paper>
                            </Grow>
                            )}
                        </Popper>
                    </div>
                    <div className='col'>
                        <TextField size="small" id="outlined-basic" label="N° Venta" 
                            variant="outlined" onChange= {(event) => {setNumVentaNueva(event.target.value)}}
                        />
                        {alertaNumVenta? numVentaNueva===''?
                            <p style={{color:'red'}}>
                                *Este campo es requerido
                            </p> :void 0
                            :
                            void 0
                        }
                    </div>
                    <div className='col'>
                        <TextField  size="small" onChange= {(event) => {setObsAccion(event.target.value)}}
                            id="outlined-basic" label="Obervación" 
                            variant="outlined" 
                        />
                    </div>
                </div>
            </div>
            <hr></hr>
            <div>
                <h4>PIEZAS EN BODEGA</h4>
                <div style={{ height: 600, width: '100%' }}>
                    {renderConfirmDialog()}
                    <TextField className='mb-2' size='small' id="filled-basic" label="Buscar" variant="outlined" 
                        onChange={(event)=>setTextoBuscado(event.target.value)}
                    />
                    <DataGrid
                        rows={piezasState}
                        columns={Columnas.getColumnasTabla(token,onClickSeleccionPieza,eliminarPieza)}
                        getRowId={(row)=>row.id}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10,20,50]}
                        disableSelectionOnClick
                        isCellEditable={(params) => params.row.estado !== 'RECIBIDO' }
                        experimentalFeatures={{ newEditingApi: true }}
                        components={{ Toolbar: GridToolbar }}
                        processRowUpdate={processRowUpdate}
                        getRowClassName={(params) =>
                            piezasSeleccionadas.find( pieza => pieza.codigo === params.row.codigo && pieza.descripcion === params.row.descripcion )?'select-row':
                            params.row.cantidad===0?'agotada-row': ""
                        }
                    />
                </div>
            </div>
            {/* MODALES */}
            <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Exito!'} />
            <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error'} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    piezas: state.desarmePiezas,
});
  
const mapDispatchToProps = {
    dispatchPiezasDesarme: actions.fetchPiezasDesarme,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsDesarme);
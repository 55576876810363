import { desarmePiezasReducer } from "./desarmePiezas-reducer";
import { desarmeSolicitudesReducer } from "./desarmeSolicitudes-reducer";
import { accionesDesarmeReducer } from "./accionesDesarme-reducer";
import {
  comprasReducer,
  UltcomprasReducer,
  proveedoresReducer,
} from "./compras-reducer";
import { combineReducers } from "redux";
import { comprasSolicReducer } from "./comprasSolicitudes-reducer";
import { solicSinMatchReducer } from "./comprasSolicitudesMalas-reducer";
import {
  bodegasReducer,
  racksReducer,
  filasReducer,
  putStockReducer,
  insertProductStockReducer,
  stockReducer, //TODO: Verificar...
  cajasReducer,
} from "./stock.reducer";
import { actComprasReducer } from "./actCompras-reducer";
import { pedidosDumpReducer } from "./pedidosDump-reducer";

var allReducers = combineReducers({
  desarmePiezas: desarmePiezasReducer,
  desarmeSolicitudes: desarmeSolicitudesReducer,
  acciones: accionesDesarmeReducer,
  compras: comprasReducer,
  comprasSolic: comprasSolicReducer,
  invoicesSinMatch: solicSinMatchReducer,
  ultComprasIng: UltcomprasReducer,
  proveedores: proveedoresReducer,
  stock: stockReducer,
  bodegas: bodegasReducer,
  racks: racksReducer,
  filas: filasReducer,
  cajas: cajasReducer,
  putStock: putStockReducer,
  addProductToStock:insertProductStockReducer,
  actCompras:actComprasReducer,
  pedidosDump: pedidosDumpReducer
});
export default allReducers;

import { createStore, applyMiddleware } from "redux";
import allReducers from "../reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxThunk from "redux-thunk";
import { createLogger } from "redux-logger";


//collapse all logs
// var logger = createLogger({
//   collapsed: true
// });

//collapse only FETCH_TASKS_REQUEST
// var logger = createLogger({
//   collapsed: (getState, action, logEntry) => {
//     return action.type === actionTypes.FETCH_TASKS_REQUEST
//   }
// });

//expand only when state has "error" object
// var logger = createLogger({
//   collapsed: (getState, action, logEntry) => {
//     return !logEntry.nextState.tasks.error;
//   }
// });

var logger = createLogger({
  predicate: (getState, action) => {
    return process.env.REACT_APP_ENVIRONMENT === "development";
  }
});

//custom middleware
/*const myLogger = (store) => (next) => (action) => {
    // console.log(store);
    // console.log(next);
    // console.log(action);
    if (action.type === actionTypes.f)
    {
      action.payload.id = uuid();
    }
    next(action); //call next middleware / reducer
  };*/

var store = createStore(allReducers, composeWithDevTools(applyMiddleware( reduxThunk, logger)));
export default store;

import React,{useEffect, useState,useRef} from 'react';
import axios from "axios";
import actions from "../../actions";
import {Paginacion} from '../../services/Services';
import ReactToPrint from 'react-to-print';
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import CheckIcon from "@mui/icons-material/Check";
import {ExcelRenderer} from 'react-excel-renderer';
import "./compras.css"
import { MDBBtn,
    MDBIcon
  } from 'mdb-react-ui-kit';

import {
    URL_DESHACER_INVOICE,URL_FETCH_PREPAREUPDATE
} from "../../config";
import ModalArchivoInvoice from './Modales/ModalArchivoInvoice';
/*mui*/
import { Autocomplete, Chip, LinearProgress, TextField, Alert, ButtonGroup, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import FormControl from '@mui/material/FormControl';
import frLocale from 'date-fns/locale/fr';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DataGrid,GridActionsCellItem,GridToolbar} from '@mui/x-data-grid';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ModalForzarOC from './Modales/ModalForzarOC';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalExito from '../Modales/ModalExito';
import ModalError from '../Modales/ModalError';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

function IngresoInvoice({token,compras,actCompras,dispatchActCompras,pedidosDump,dispathcPedidosDump,dispatchFetchCompras}) {
/*data grid */
    const columnsPedidosDump = [
        {
            field: 'codigo_dess',
            headerName: 'Codigo DESS',
            width: 150,
        },
        {
            field: 'num_parte',
            headerName: 'Num Parte',
            width: 150,
        },
        {
            field: 'descripcion',
            headerName: 'Descripcion',
            sortable: false,
            width: 350,
            sortable:true
        },
        {
            field: 'marca_auto',
            headerName: 'Marca Auto',
            sortable: false,
            width: 150
        },
        {
            field: 'cantidad',
            headerName: 'Cantidad',
            sortable: false,
            width: 150
        },
        {
            field: 'codigo_invoice',
            headerName: 'Codigo Invoice',
            width: 150,
        },
        {
            field: 'num_oc',
            headerName: 'num oc',
            width: 150,
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<AddLinkIcon />}
                    label="VINCULAR OC EXISTENTE"
                    onClick={()=>showModalForzarOC.current(params.row)}
                    disabled={params.row.num_oc?true:false}
                    showInMenu
                />,
            ]
        }
    ];

    const columnsActCompras = [
        {
            field: 'estado',
            headerName: 'Estado',
            width: 150,
            sortable:true,
            renderCell: (elem) => (
            <div>
            <Chip icon={elem.row.estado === 'RECIBIDO'?<CheckIcon />:<ErrorOutlineIcon />} label={elem.row.estado} variant="outlined" 
                color={elem.row.estado === 'RECIBIDO'? "success" :elem.row.estado === 'ATRASADO'? 'error': "warning"}
            />
            </div>
            )
        },
        {
            field: 'num_oc',
            headerName: 'Num Orden',
            width: 150,
            sortable:true
        },
        {
            field: 'num_parte',
            headerName: 'Num Parte',
            width: 110,
            sortable:true
        },
        {
            field: 'codigo_dess',
            headerName: 'Codigo DESS',
            width: 150,
            sortable:true
        },
        {
            field: 'descripcion',
            headerName: 'Descripcion',
            width: 350,
            sortable:true
        },
        {
            field: 'cantidad',
            headerName: 'Cantidad Solicitada',
            sortable: false,
            width: 150
        },
        {
            field: 'cantidad_recibida',
            headerName: 'Cantidad Recibida',
            sortable: false,
            width: 150
        },
        {
            field: 'codigo_invoice',
            headerName: 'Codigo Invoice',
            width: 150,
            sortable:true
        },
    ];
    /*date picker */
    let ts = Date.now();
    let fecha_hoy = new Date(ts);
    const [fechaInicial, setFechaInicial] = useState(null);
    const [fechaFinal, setFechaFinal] = useState(null);
    let [fechaBuscada0, setFechaBuscada0] = useState(null);
    //-------- state --------//
    let [actComprasState,setActComprasState] = useState([]);
    let [pedidosDumpState,setPedidosDumpState] = useState([]);
    let [codigoInvoiceDeshacer, setCodigoInvoiceDeshacer] = useState("");
    let [tiendaSelec,setTiendaSelec] = useState('')
    let [isSelected,setIsSelected] = useState(false);
    let [horaIngresoArchivo,setHoraIngresoArchivo ] = useState("");
    let [ordenes,setOrdenes] = useState([]);
    let [ordenSinOcCarga,setOrdenSinOcCarga] = useState();
    let [invoiceBuscada,setInvoiceBuscada] = useState();
    let [loaderInvoice,setLoaderInvoice] = useState(false);
    //----------- paginacion de tabla ----------------//
    let [pageSize,setPageSize] = useState(10);
    let [pages, setPages] = useState([]);
    let [currentPageIndex, setCurrentPageIndex] = useState(0);

    let [ordenesSinMatchIngresadas,setOrdenesSinMatchIngresadas]=useState([]);

    //-------- variables de archivo excel --------//
    let [archivoExcel,setArchivoExcel] = useState();
    let fileHandler = (event) => {
        let fileObj = event.target.files[0];
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
        if(err){
            console.log(err);            
        }
        else{
            if(validationExcelInvoice(resp)) {
                setArchivoExcel({
                    cols: resp.cols,
                    rows: resp.rows,
                    file: fileObj
                });
            }
        }
        });               
    }

    // Validación de las columnas en fichero excel
    let validationExcelInvoice = (resp) => {
        let cols = resp.cols;
        if (cols.length !== 8) {
            alert("El fichero cargado no tiene el formato correcto. Por favor, cargue un excel con el formato correcto.");
            return false;
        }
        return true;
    }

    /*Filtros */
    //------------LOGICA DE FILTROS!!-------------------//
    let filtActCompras = compras.data.filter(elem => 
        elem.codigo_invoice != null
    );

    let invoicesFiltFecha0 = filtActCompras.filter(elem => 
        fechaBuscada0?
            elem.codigo_invoice.indexOf(fechaBuscada0.toLocaleDateString("en-GB")) >= 0
        : true
    );
    let invoicesSinCruceFiltFecha0 = pedidosDump?.data.filter(elem => 
        fechaBuscada0?
            elem.fecha_ingreso.indexOf(fechaBuscada0.toLocaleDateString("en-GB")) >= 0
        : true
    );

    let filtInvoice = invoicesFiltFecha0.filter(elem => 
        invoiceBuscada?
        //elem.fecha_ult_act.indexOf(fechaBuscada0 || true) >= 0 &&
        elem.codigo_invoice.indexOf(invoiceBuscada) >= 0
        :
        true
    );

    let filtInvoiceSinCruce = invoicesSinCruceFiltFecha0.filter(elem => 
        invoiceBuscada ?
        //elem.fecha_ingreso.indexOf(fechaBuscada0) >= 0 &&
        elem.codigo_invoice.indexOf(invoiceBuscada) >= 0
        :
        true
    );

    /*agrupacion de codigos invoices */
    let codigosInvoices= invoicesFiltFecha0.map((elem)=>{
        return(
            elem.codigo_invoice
        )
    })
    let codigosInvoicesSinCruce= invoicesSinCruceFiltFecha0.map((elem)=>{
        return(
            elem.codigo_invoice
        )
    })
    let codigosConcatenados = codigosInvoices.concat(codigosInvoicesSinCruce);

    let codigosUnicos = codigosConcatenados.sort((a, b) => -b.localeCompare(a)).filter((elem,index) => 
        elem != codigosConcatenados[index+1]
    );

    //------- effects -------//
    useEffect(() =>{
        dispatchActCompras();
        dispathcPedidosDump();
        setCurrentPageIndex(0);
        setFechaBuscada0(fecha_hoy);
    },[])

    useEffect(() =>{
        if(archivoExcel){
            setIsSelected(true);
        }
    },[archivoExcel]);

    useEffect(() =>{
        setActComprasState(filtInvoice);
        setPedidosDumpState(filtInvoiceSinCruce);
    },[pedidosDump,compras,pedidosDump.loadig,compras.loading,invoiceBuscada,fechaBuscada0]);

    //Funcion de paginacion
    /*
    useEffect(() => {
        //calcula el numro de paginas y crea un objeto pagina
        setPages(Paginacion.getPagesArray(filtInvoice, pageSize));
        //paging
        let pagedArray = Paginacion.getRowsByPageIndex(
            filtInvoice,
            currentPageIndex,
            pageSize
        );
        //get sorted products
        setOrdenes(pagedArray);
    }, [actCompras.loading,pedidosDump.loading,fechaBuscada0,invoiceBuscada])
    */
    //-------- SNACKBAR--------//
    const [isLoadingIngresarProducto, setIsLoadingIngresarProducto] = useState(false);
    const [openIngresoProductosSnackBar, setopenIngresoProductosSnackBar] = useState(false);
    const handleCloseIngresoProductos = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setopenIngresoProductosSnackBar(false);
    };
    const [messageIngresoProductosSnackBar, setMessageIngresoProductosSnackBar] = useState("Success");
    const action = (
    <React.Fragment>
        <CheckIcon></CheckIcon>
    </React.Fragment>
    );

    let deshacerInvoice = async() => {
        await axios.post(URL_DESHACER_INVOICE,
            {codigoInvoiceDeshacer,token})
            .then((resp)=>{
              if(resp.status===200){
                  showModalExito.current();
              }
            })
            .catch((error)=>{
                showModalError.current();
            }
        );
    }
    /*Funciones y otros */
    let actualizarListado = ()=>{
        //dispatchActCompras();
        dispathcPedidosDump();
        dispatchFetchCompras();
        actualizarComprasInvoices();
    }

    let actualizarComprasInvoices = async() => {
        console.log("actualizarComprasInvoices")
        await axios.get(URL_FETCH_PREPAREUPDATE)
            .then((resp)=>{
              if(resp.status===200){
                  //showModalExito.current();
                  //actualizarListado();
                  dispathcPedidosDump();
                  dispatchFetchCompras();
              }
            })
            .catch((error)=>{
                showModalError.current();                
            }
        );
    }
    /*ref para imprimir tabla */
    const tablaActRef = useRef();
    /* Activadores de modales */
    const showModalArchivo = useRef(null);
    const showModalForzarOC = useRef(null);

    let cambiarLoader = (i)=>{
        console.log("cambiar load",i)
        setLoaderInvoice(!loaderInvoice);
        setTimeout(function() {
            setLoaderInvoice(false);
            //dispatchActCompras();
            dispathcPedidosDump();
            actualizarComprasInvoices();
        }, 800 * i );
    }

    let cambioTienda = (event) => {
        setTiendaSelec(event.target.value);        
    }

    let mostrarModalArchivo = async()=>{
        showModalArchivo.current();
    }

    //activador modal
    const showModalExito = useRef(null);
    const showModalError = useRef(null);
    
    return (
        <React.Fragment>
        <div className='row'>
            <div className='col-12'>
                <h5>SUBIR ARCHIVO DE INVOICE</h5>
                <hr/>
            </div>
            <div className='col mb-3'>
                <label htmlFor="formFile" className="form-label" >
                Arrastre o seleccione un archivo.
                </label>
                <input className="form-control" type="file" name="file" id="fileInvoice" onChange={fileHandler.bind(this)} />
                <Button variant="contained" sx={{marginRight:10, marginTop:2}} 
                    onClick={() => mostrarModalArchivo()} disabled={!isSelected || tiendaSelec==''}
                >
                    Pre-visualizar
                </Button>
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Cargar invoice para:</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={tiendaSelec}
                        onChange={cambioTienda}
                    >
                        <FormControlLabel value="AUTOFIX" control={<Radio />} label="AUTOFIX" />
                        <FormControlLabel value="24LEMANS" control={<Radio color='warning' />} label="24LEMANS" />
                        <FormControlLabel value="IMPORT" control={<Radio color='secondary'/>} label="IMPORT"/>
                    </RadioGroup>
                </FormControl>
            </div>
            {archivoExcel ? (
                <div className='col'>
                    <p>Nombre de archivo: {archivoExcel.file.name}</p>
                    <p>Tipo de archivo: {archivoExcel.file.type}</p>
                    <p>Tamaño en bytes bytes: {archivoExcel.file.size}</p>
                </div>
                ) : (
                <div className='col mt-4'>
                    <Alert severity="info"> Seleccione un archivo para actualizar los estados de los pedidos.</Alert>
                </div>
            )}
        </div>
        <div ref={tablaActRef}>
            <div className='row mt-4 mb-2'>
                <div className='col-12'>
                    <div className="d-inline-flex">
                        <h5 style={{marginRight:'20px'}}>INFORME DE INGRESO</h5>
                        <Button variant="outlined" color="primary" onClick={()=>{actualizarListado()}} >
                            Actualizar <ReplayIcon />
                        </Button>
                    </div>
                    <hr/>
                </div>
                <div className='col-2'>
                    <TextField disabled id="filled-basic" label="Buscar" variant="outlined" />
                </div>
                <div className='col-2'>
                    <FormControl size='small'>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                            <DatePicker
                                label="Fecha Invoice"
                                value={fechaBuscada0}
                                onChange={(newValue) => {
                                    setFechaBuscada0(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
                <div className='col-3'>
                    <Autocomplete
                        sx={{width: 250}}
                        id="grouped-demo"
                        options={codigosUnicos}
                        getOptionLabel={(option) => option}
                        onChange={(event,value)=>setInvoiceBuscada(value) }
                        renderInput={(params) => <TextField {...params} label="Codigo Invoice" />}
                    />
                </div>
                <div className='col-2 ml-5'>
                <FormControl sx={{width: '25ch' }} variant="outlined">
                <InputLabel>Deshacer Invoice</InputLabel>
                <OutlinedInput
                    onChange= {(event) => {setCodigoInvoiceDeshacer(event.target.value)}}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={()=>deshacerInvoice()}
                                edge="end"
                                disabled={codigoInvoiceDeshacer===""?true:false}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Deshacer Invoice"
                />
                </FormControl>
                </div>
            </div>
        </div>
        <div style={{ height: 600, width: '100%', marginBottom:80}}>
            <p className='mt-3'>PEDIDOS ACTUALIZADOS</p>
            {loaderInvoice &&  <LinearProgress color="secondary" />}
            <DataGrid
                rows={actComprasState}
                columns={columnsActCompras}
                getRowId={(row)=>row.codigo}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10,20,50]}
                disableSelectionOnClick
                components={{ Toolbar: GridToolbar }}
            />
        </div>
                            
        <div style={{ height: 600, width: '100%' }}>
            <p>INVOICES SIN UN PEDIDO RELACIONADO(FALLA EN CRUCE DE CODIGOS)</p>
            {loaderInvoice &&  <LinearProgress color="secondary" />}
            <DataGrid
                rows={pedidosDumpState}
                columns={columnsPedidosDump}
                getRowId={(row)=>row.codigo}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10,20,50]}
                disableSelectionOnClick
                components={{ Toolbar: GridToolbar }}
                getRowClassName={(params) => params.row.num_oc && 'pedidoVinculado'}
            />
        </div>
        {/*MODALES*/}
        <ModalExito showModal={showModalExito}  titulo={'Exito'} mensaje={'Acción realizada con éxito'} />
        <ModalError showModal={showModalError} titulo={'Fallo operación '} mensaje={'No se pudo realizar acción'} />
        {archivoExcel &&
            <ModalArchivoInvoice setLoader={cambiarLoader} 
                showModal={showModalArchivo} data={archivoExcel.rows} 
                columns={archivoExcel.cols} file={archivoExcel.file} token={token} 
                tiendaSelec={tiendaSelec}
            />
        }
        <ModalForzarOC showModal={showModalForzarOC} />
        <Snackbar
          open={openIngresoProductosSnackBar}
          autoHideDuration={4000}
          onClose={handleCloseIngresoProductos}
          message={messageIngresoProductosSnackBar}
          action={action}
        />
        <ReactToPrint
            trigger={() => <MDBBtn color='success'><MDBIcon fas icon="print" /> IMPRIMIR INFORME</MDBBtn>}
            content={() => tablaActRef.current}
        />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    actCompras: state.actCompras,
    pedidosDump: state.pedidosDump,
    compras: state.compras
});
  
const mapDispatchToProps = {
    dispatchActCompras: actions.fetchActCompras,
    dispathcPedidosDump: actions.fetchPedidosDump,
    dispatchFetchCompras: actions.fetchCompras
};

export default connect(mapStateToProps, mapDispatchToProps)(IngresoInvoice);

import React,{useState} from 'react'
import SeguimientoOC from './SeguimientoOC';
import IngresoInvoice from './IngresoInvoice';
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane
  } from 'mdb-react-ui-kit';
import {hasRoleAdmin} from "../../core/auth/auth";

export default function OrdenesCompraWizard({token}) {
    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
        return;
        }

        setBasicActive(value);
    };

    return (
        <div className='col'>
            <div className='container shadow' style={{marginTop:'20px',paddingBottom:'20px', backgroundColor:'white'}}>
                <div className='container'>
                    <MDBTabs className='mb-3'>
                    <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                        Ordenes de Compra
                    </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                    {hasRoleAdmin(token) &&
                    <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                        Ingreso de Invoice
                    </MDBTabsLink>
                    }
                    </MDBTabsItem>
                    </MDBTabs>
                    
                    <MDBTabsContent>
                        <MDBTabsPane show={basicActive === 'tab1'}> <SeguimientoOC token={token}/> </MDBTabsPane> 
                        {hasRoleAdmin(token)&&  
                            <MDBTabsPane show={basicActive === 'tab2'}> <IngresoInvoice token={token}/> </MDBTabsPane> 
                        }  
                    </MDBTabsContent>
                </div>
            </div>
        </div>
    )
}

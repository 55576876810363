import { hasRoleAdmin } from '../../core/auth/auth';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';
import React from 'react';
import { Chip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CachedIcon from '@mui/icons-material/Cached';
import BugReportIcon from '@mui/icons-material/BugReport';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import ErrorIcon from '@mui/icons-material/Error';
import EngineeringIcon from '@mui/icons-material/Engineering';
import UpgradeIcon from '@mui/icons-material/Upgrade';

export const TicketsHeaders = {
  getColumnasTabla: (token, editTicket) => {
    const columns = [
      {
        field: 'codigo',
        headerName: 'Código',
        width: 80
      },
      {
        field: 'tipo',
        headerName: 'Tipo',
        width: 200,
        renderCell: (elem) => (
          <div>
          <Chip 
          icon={
            elem.row.tipo === 'REQUERIMIENTO'?<EngineeringIcon /> :
            elem.row.tipo === 'MEJORA'?<UpgradeIcon /> :
            <ErrorIcon />
          } 
          color={
            elem.row.tipo === 'REQUERIMIENTO'? "warning" :
            elem.row.tipo === 'MEJORA'? 'info': 
            "error"
          }
          label={elem.row.tipo} variant="outlined" 
          />
          </div>
        )
      },
      {
        field: 'titulo',
        headerName: 'Titulo',
        width: 300
      },
      {
        field: 'autor',
        headerName: 'Autor',
        width: 150
      },
      {
        field: 'fecha_creacion',
        headerName: 'Fecha Creación',
        width: 150
      },
      {
        field: 'fecha_modificacion',
        headerName: 'Fecha Modificación',
        width: 150
      },
      {
        field: 'estado',
        headerName: 'Estado',
        width: 150,
        renderCell: (elem) => (
          <div>
          <Chip 
          icon={
            elem.row.estado === 'RESUELTO'?<CheckIcon /> :
            elem.row.estado === 'EN CURSO'?<CachedIcon /> :
            elem.row.estado === 'EN PRUEBAS'?<BugReportIcon /> :
            elem.row.estado === 'CERRADO'?<DoDisturbOnIcon /> :
            <ErrorOutlineIcon />
          } 
          color={
            elem.row.estado === 'RESUELTO'? "success" :
            elem.row.estado === 'EN CURSO'? 'info': 
            elem.row.estado === 'EN PRUEBAS'? 'primary': 
            elem.row.estado === 'CERRADO'? 'secondary': 
            "warning"
          }
          label={elem.row.estado} variant="outlined" 
          />
          </div>
        )
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params) => [
          <GridActionsCellItem icon={<EditIcon />}
            label="Delete"
            onClick={() => editTicket(params.row)}
            disabled={!hasRoleAdmin(token)}
          />
        ]
      }
    ];
    return columns;
  }
}
import * as actionTypes from "../constants/action-types";
import axios from "axios";
import {
    URL_FETCH_STOCK,
    URL_FETCH_BODEGAS,
    URL_FETCH_RACKS_BY_ID_BODEGA,
    URL_PUT_COMPRA_STOCK_BY_ID_CAJA,
    URL_INSERT_PRODUCTOS_STOCK,
    URL_FETCH_FILAS_BY_ID_RACK,
    URL_FETCH_CAJAS_BY_ID_FILA
} from "../config";

export const fetchStock = () => async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_STOCK });
    try {
        var response = await axios.get(URL_FETCH_STOCK);
        dispatch({
            type: actionTypes.FETCH_STOCK_SUCCESS,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: actionTypes.FETCH_STOCK_ERROR,
            payload: err
        });
    }
};

export const updateStock = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_STOCK });
    try {
        var response = await axios.post(URL_FETCH_STOCK, data);
        dispatch({
            type: actionTypes.UPDATE_STOCK_SUCCESS,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: actionTypes.UPDATE_STOCK_ERROR,
            payload: err
        });
    }
}

//API Stock
export const fetchBodegas = () => async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_BODEGAS });
    try {
        var response = await axios.get(URL_FETCH_BODEGAS);
        dispatch({
            type: actionTypes.FETCH_BODEGAS_SUCCESS,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: actionTypes.FETCH_BODEGAS_ERROR,
            payload: err
        });
    }
}

export const fetchRacksByIdBodega = (idBodega) => async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_RACKS_BY_ID_BODEGA });
    try {
        const url = URL_FETCH_RACKS_BY_ID_BODEGA + '/' + idBodega;
        var response = await axios.get(url);
        dispatch({
            type: actionTypes.FETCH_RACKS_BY_ID_BODEGA_SUCCESS,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: actionTypes.FETCH_RACKS_BY_ID_BODEGA_ERROR,
            payload: err
        });
    }
}

export const fetchFilasByIdRack = (idRack) => async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FILAS_BY_ID_RACK });
    try {
        const url = URL_FETCH_FILAS_BY_ID_RACK + '/' + idRack;
        var response = await axios.get(url);
        dispatch({
            type: actionTypes.FETCH_FILAS_BY_ID_RACK_SUCCESS,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: actionTypes.FETCH_FILAS_BY_ID_RACK_ERROR,
            payload: err
        });
    }
}

export const fetchCajasByIdFila = (idFila) => async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_CAJAS_BY_ID_FILA });
    try {
        const url = URL_FETCH_CAJAS_BY_ID_FILA + '/' + idFila;
        var response = await axios.get(url);
        dispatch({
            type: actionTypes.FETCH_CAJAS_BY_ID_FILA_SUCCESS,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: actionTypes.FETCH_CAJAS_BY_ID_FILA_ERROR,
            payload: err
        });
    }
}

export const putCompraByIdCaja = (idBodega, idRack, idFila, idCaja, codigo,cantidadAlm) => async (dispatch) => {
    dispatch({ type: actionTypes.PUT_COMPRA_STOCK });
    try {
        const url = URL_PUT_COMPRA_STOCK_BY_ID_CAJA;
        var response = await axios.put(url, { idBodega, idRack, idFila, idCaja, codigo,cantidadAlm });
        dispatch({
            type: actionTypes.PUT_COMPRA_STOCK_SUCCESS,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: actionTypes.PUT_COMPRA_STOCK_ERROR,
            payload: err
        });
    }
}

export const postProductoStock = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.POST_PRODUCTO_STOCK });
    try {
        var response = await axios.post(URL_INSERT_PRODUCTOS_STOCK, data);
        dispatch({
            type: actionTypes.POST_PRODUCTO_STOCK_SUCCESS,
            payload: response.data
        });
    }
    catch (err) {
        dispatch({
            type: actionTypes.POST_PRODUCTO_STOCK_ERROR,
            payload: err
        });
    }
}
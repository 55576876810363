import * as actionTypes from "../constants/action-types";

//reducer is a function that receives an action and returns new state.
let initialState = { data: [], loading: false, error: "" };

export const stockReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_STOCK:
        return { data: [], loading: true, error: "" };
    
        case actionTypes.FETCH_STOCK_SUCCESS:
        return { data: action.payload, loading: false, error: "" };
    
        case actionTypes.FETCH_STOCK_ERROR:
        return { data: state, loading: false, error: action.payload };

        case actionTypes.UPDATE_STOCK:
        return { data: [], loading: true, error: "" };

        case actionTypes.UPDATE_STOCK_SUCCESS:
        return { data: action.payload, loading: false, error: "" };

        case actionTypes.UPDATE_STOCK_ERROR:
        return { data: state, loading: false, error: action.payload };
    
        default:
        return state;
    }
};

// API STOCK

export const bodegasReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_BODEGAS:
        return { data: [], loading: true, error: "" };
    
        case actionTypes.FETCH_BODEGAS_SUCCESS:
        return { data: action.payload, loading: false, error: "" };
    
        case actionTypes.FETCH_BODEGAS_ERROR:
        return { data: state, loading: false, error: action.payload };
    
        default:
        return state;
    }
}

export const racksReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_RACKS_BY_ID_BODEGA:
        return { data: [], loading: true, error: "" };
    
        case actionTypes.FETCH_RACKS_BY_ID_BODEGA_SUCCESS:
        return { data: action.payload, loading: false, error: "" };
    
        case actionTypes.FETCH_RACKS_BY_ID_BODEGA_ERROR:
        return { data: state, loading: false, error: action.payload };
    
        default:
        return state;
    }
}

export const filasReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FILAS_BY_ID_RACK:
        return { data: [], loading: true, error: "" };

        case actionTypes.FETCH_FILAS_BY_ID_RACK_SUCCESS:
        return { data: action.payload, loading: false, error: "" };

        case actionTypes.FETCH_FILAS_BY_ID_RACK_ERROR:
        return { data: state, loading: false, error: action.payload };

        default:
        return state;
    }
}

export const cajasReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CAJAS_BY_ID_FILA:
        return { data: [], loading: true, error: "" };

        case actionTypes.FETCH_CAJAS_BY_ID_FILA_SUCCESS:
        return { data: action.payload, loading: false, error: "" };

        case actionTypes.FETCH_CAJAS_BY_ID_FILA_ERROR:
        return { data: state, loading: false, error: action.payload };

        default:
        return state;
    }
}

export const putStockReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PUT_COMPRA_STOCK:
        return { data: [], loading: true, error: "" };

        case actionTypes.PUT_COMPRA_STOCK_SUCCESS:
        return { data: action.payload, loading: false, error: "" };

        case actionTypes.PUT_COMPRA_STOCK_ERROR:
        return { data: state, loading: false, error: action.payload };

        default:
        return state;
    }
}

export const insertProductStockReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.POST_PRODUCTO_STOCK:
        return { data: [], loading: true, error: "" };

        case actionTypes.POST_PRODUCTO_STOCK_SUCCESS:
        return { data: action.payload, loading: false, error: "" };

        case actionTypes.POST_PRODUCTO_STOCK_ERROR:
        return { data: state, loading: false, error: action.payload };

        default:
        return state;
    }
}

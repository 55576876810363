import { Button } from "@mui/material";
import { useHistory } from "react-router";

export default function EditStock() {
  const history = useHistory();

  return (
    <>
      <div className="container">
      <Button onClick={history.goBack}>Atras</Button>
      </div>
    </>
  );
}

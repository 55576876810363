export const Paginacion = {
  getPagesArray: (elements, pageSize) => {
    let noOfPages = Math.ceil(elements.length / pageSize); //Example: 23 / 5 = 4.6 = 5 pages
    let pagesArray = [];
    for (let i = 0; i < noOfPages; i++) {
      pagesArray.push({ pageIndex: i });
    }

    //Example: [ { pageIndex: 0 }, { pageIndex: 1 }, { pageIndex: 2 } ]
    return pagesArray;
  },
  getRowsByPageIndex: (elements, currentPageIndex, pageSize) => {
    /*Example
      elements = [ 20 elements ]
      currentPageIndex = 2
      pageSize = 5
      return [ 10, 11, 12, 13, 14 ]
    */

    if (!elements) return elements;

    let array = [...elements];
    let resultArray = [];
    for (
      let i = currentPageIndex * pageSize;
      i < (currentPageIndex + 1) * pageSize;
      i++
    ) {
      if (array[i]) resultArray.push(array[i]);
    }
    return resultArray;
  },
};
/*------------ SORT(ORDENAR)----------------- */
export const SortService = {
  getSortedArray: (elements, sortBy, sortOrder) => {
    if (!elements) return elements;

    let array = [...elements];

    array.sort((a, b) => {
      if(sortBy === 'cantidad' || sortBy === 'costo_unitario' || sortBy === 'costo_envio'|| sortBy === 'costo_total'){
        return (parseInt(a[sortBy])-parseInt(b[sortBy]))
      }
      else if(sortBy === 'fecha_estimada_llegada'){
        return (new Date(a[sortBy].split('-').reverse().join('-')) - new Date(b[sortBy].split('-').reverse().join('-')))
      }
      else if(sortBy === 'fechaoc'){
        return (new Date(a[sortBy].split('-').reverse().join('-')) - new Date(b[sortBy].split('-').reverse().join('-')))
      }
      else{
        return a[sortBy].localeCompare(b[sortBy]);
      }
      
    });

    if (sortOrder === "DESC") array.reverse();

    return array;
  },
};


import React from 'react';
import { CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

// Función para mostrar un diálogo de carga (loader)
export function showLoader(isLoading) {
    return (
        <Backdrop
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}


import React from 'react';
import NoMatchPage from "./components/NoMatchPage/NoMatchPage";
import Inicio from "./components/Inicio/Inicio";
import { HashRouter } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Provider } from "react-redux";
import store from "./store";
import WizardDesarme from './components/Desarme/WizardDesarme';
import Login from './components/Login/Login';
import OrdenesCompraWizard from './components/GestionCompras/OrdenesCompraWizard.js'
import useToken from './UseToken';
import Stock from './components/Stock/Stock';
import PersistentDrawerLeft from './components/Drawer/Drawer';
import EditStock from './components/Stock/EditStock';
import ListSupportTicket from './components/Soporte/ListSupportTicket';

require('dotenv').config();

function App() {
  let { token, setToken } = useToken();
    //let [token, setToken] = useState();

  if(!token || token.length === 0) {
    console.log(token)
    return <Login setToken={setToken} />
  }

  return (
    <div  style={{maxWidth:'100vw',backgroundColor:'#F2F0F0'}}> 
          <div style={{backgroundColor:'white'}}>
          <HashRouter>
          <Provider store={store}>
          <PersistentDrawerLeft token={token} setToken={setToken}>
            <Switch>
            <Route path="/" exact={true} component={() => <Inicio token={token} />} />
            <Route path="/desarme" exact={true} component={() => <WizardDesarme token={token} />}/>
            <Route path="/ordenescompra" exact={true} component={() => <OrdenesCompraWizard token={token} />}/>
            <Route path="/stock" exact={true} component={() => <Stock token={token} />}/>
            <Route path="/stock24lemans" exact={true} component={() => <Stock token={token} lemans={true} />}/>
            <Route path="/stockImports" exact={true} component={() => <Stock token={token} imports={true} />}/>
            <Route path="/stock/edit/:id" exact={true} component={() => <EditStock token={token} />}/>
            <Route path="/soporte" exact={true} component={() => <ListSupportTicket token={token} />}/>
            <Route path="*" component={NoMatchPage} />
            </Switch>
          </PersistentDrawerLeft>
          </Provider>
          </HashRouter>
          {/* <Sidebar token={token} setToken={setToken} /> */}
          </div>
          
      </div>
  )
}

export default App;

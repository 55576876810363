import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import TicketsService from '../../services/Soporte/TicketsService';
import { TicketsHeaders } from '../../services/Soporte/TicketsHeaders';
import 'react-quill/dist/quill.snow.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import AddSupportTicket from './AddSupportTicket';
import Button from '@mui/material/Button';
import { LinearProgress } from "@mui/material";

export default function ListSupportTicket({ token }) {
    let [tickets, setTickets] = useState([]);
    let [pageSize, setPageSize] = useState(10);
    let [dialogOpen, setDialogOpen] = useState(false);
    let [ticketToEdit, setTicketToEdit] = useState(null);
    let [isLoading, setIsLoading] = useState(false);

    /**
     * Funciones
     */
    useEffect(() => {
        getTickets();
    }, []);

    const getTickets = () => {
        setIsLoading(true);
        TicketsService.getTickets()
        .then((resp) => {
            setTickets(resp);
            setIsLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
            setIsLoading(false);
        });
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTicketToEdit(null);
    };

    const editTicket = (row) => {
        setTicketToEdit(row)
        handleOpenDialog();
    };

    const handleSave = () => {
        handleCloseDialog();
        getTickets();
    }

    /**
     * HTML
     */
    return (
        <React.Fragment>
            <div className='container module-header'>
                <h3>Soporte Técnico</h3>
            </div>
            <br/>
            <div className='container shadow pt-4' style={{ paddingBottom: 20 }}>
                <Button className='mb-3' onClick={handleOpenDialog} variant="contained">Crear Ticket</Button>
                <div style={{ height: 600, width: '100%' }}>
                    {isLoading && <LinearProgress color="success" />}
                    <DataGrid
                        rows={tickets}
                        columns={TicketsHeaders.getColumnasTabla(token, editTicket)}
                        getRowId={(row) => row.codigo}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 50]}
                        disableSelectionOnClick
                    />
                </div>
            </div>
            <Dialog 
                open={dialogOpen} 
                onClose={handleCloseDialog} 
                fullWidth={true} 
                maxWidth={'xl'}>
                <DialogTitle>{ticketToEdit ? 'Editar Ticket' : 'Crear Ticket'}</DialogTitle>
                <DialogContent>
                    <AddSupportTicket 
                        token={token} 
                        ticketToEdit={ticketToEdit} 
                        onSave={handleSave} onClose={handleCloseDialog}
                    />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
import * as actionTypes from "../constants/action-types";
import axios from "axios";

import {
  URL_FETCH_PIEZAS_DESARME,
  URL_FETCH_ACCIONES_DESARME,
  URL_FETCH_ULT_PIEZAS_DESARME
} from "../config";

export const fetcUltimaSolicitudIngresoPiezasDesarme = (props) => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_ULTIMA_SOLICITUD_PDESARME});
  try
  {
    var response = await axios.get(`${URL_FETCH_ULT_PIEZAS_DESARME}/${props}`);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_ULTIMA_SOLICITUD_PDESARME_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_ULTIMA_SOLICITUD_PDESARME_ERROR,
      payload: err
    });
  }
};

export const fetchPiezasDesarme = () => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_PDESARME});
  try
  {
    var response = await axios.get(URL_FETCH_PIEZAS_DESARME);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PDESARME_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PDESARME_ERROR,
      payload: err
    });
  }
};

export const fetchAccionesDesarme = () => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_ACCIONES_DESARME});
  try
  {
    var response = await axios.get(URL_FETCH_ACCIONES_DESARME);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_ACCIONES_DESARME_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_ACCIONES_DESARME_ERROR,
      payload: err
    });
  }
};
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import actions from "../../actions";
import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
/****/
import IngresoUbicacionPaso1 from "./IngresoUbicacion/IngresoUbicacionPaso1";

import {
  URL_POST_UBICACIONES
} from "../../config";
import ModalIngresoStock from "./Modales/ModalIngresoStock";
import IngresoUbicacionPaso2 from "./IngresoUbicacion/IngresoUbicacionPaso2";
import IngresoUbicacionPaso3 from "./IngresoUbicacion/IngresoUbicacionPaso3";
import ModalExito from "../Modales/ModalExito";
import ModalError from "../Modales/ModalError";

const steps = ['Seleccionar compras', 'Ubicar productos', 'Confirmar'];

function IngresoUbStock({
  token,
  stock,
  dispatchFetchStock,
  lemans
}) {

  /*Variables stepper */
  let [comprasSelec, setCodigosSelec] = useState([]);
  let [ubicaciones, setUbicaciones] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    return step === 5;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 0) {
      let comprasAux = stock.data.filter((elem) =>
        getSelecPaso1.current().includes(elem.codigo)
      );
      setCodigosSelec(comprasAux);
    } else if (activeStep === 1) {
      console.log(getSelecPaso2.current())
      setUbicaciones(getSelecPaso2.current())
    } else if (activeStep === 2) {
      post_ubicaciones();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  let post_ubicaciones = async () => {
    console.log("agrgando ubicaciones", ubicaciones);
    await axios.post(URL_POST_UBICACIONES,
      { ubicaciones, token })
      .then((resp) => {
        if (resp.status === 200) {
          console.log('hola')
          showModalExito.current();
        }
      })
      .catch((error) => {
        showModalError.current();
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    dispatchFetchStock();
  };

  /**Componentes hijos */
  const getSelecPaso1 = useRef();
  const getSelecPaso2 = useRef();
  /* Activadores de modales */
  const showModalProducto = useRef(null);

  useEffect(() => {
    dispatchFetchStock();
  }, [dispatchFetchStock]);


  //activadores modal
  const showModalExito = useRef(null);
  const showModalError = useRef(null);

  return (
    <React.Fragment>
      <div className="col mt-2">
        <div className="row mb-4 pt-2 ">
          <div className="col-3">
            <h4>Ingresar Producto</h4>
          </div>
          <div className="col">
            <Button variant="contained" color="success" onClick={() => showModalProducto.current()}>
              Agregar Producto <i className="fas fa-plus px-2"></i>
            </Button>
          </div>
        </div>

        <div className="container shadow">
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-3">
              <div
                style={{
                  marginTop: "14px",
                }}
              >
                <h4>Compras Recibidas</h4>
              </div>
            </div>

            <div className="col">
              <button
                className="btn btn-outline-primary mt-3"
                onClick={() => dispatchFetchStock()}
              >
                Actualizar listado
              </button>
            </div>
          </div>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Pasos completados
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                {activeStep === 0 ?
                  <IngresoUbicacionPaso1 getSeleccion={getSelecPaso1} lemans={lemans} />
                  : void 0
                }
                {activeStep === 1 ?
                  <IngresoUbicacionPaso2 getSeleccion={getSelecPaso2} comprasSelec={comprasSelec} lemans={lemans} />
                  : void 0
                }
                {activeStep === 2 ?
                  <IngresoUbicacionPaso3 ubicacionesSelec={ubicaciones} />
                  : void 0
                }
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    ATRAS
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )}

                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'GUARDAR' : 'SIGUIENTE'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </div>
      </div>
      {/*------- MODALES --------*/}
      <ModalIngresoStock showModal={showModalProducto} titulo={'Ingreso manual de producto'} />
      {/* MODALES */}
      <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Ingreso de OC exitosa!'} />
      <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error al ingresar OC!'} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  stock: state.stock,
});

const mapDispatchToProps = {
  dispatchFetchStock: actions.fetchStock
};

export default connect(mapStateToProps, mapDispatchToProps)(IngresoUbStock);

import React, { useState, useEffect,useRef } from "react";
import { connect } from "react-redux";
import actions from "../../../actions";
import axios from "axios";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import ModalExito from "../../Modales/ModalExito";
import ModalError from "../../Modales/ModalError";
import { URL_UPDATE_COSTOS } from "../../../config";

function ModalActCostos({
  showModal,titulo,mensaje,token
}) {
  let [compraEditar,setCompraEditar] = useState({});
  let [costoUnitEditar,setCostoUnitEditar] = useState('');
  let [costoEnvioEditar,setCostoEnvioEditar] = useState('');
  let [costoTotalEditar,setCostoTotalEditar] = useState('');
  /*LOGICA DE MODAL */
  /* Activadores modales de exito o error */
  const showModalExito = useRef(null);
  const showModalError = useRef(null);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (compra) => {
    if(compra){
      setCompraEditar(compra);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    showModal.current = handleClickOpen;
  }, [])  
  /****/

  let onClickGuardarCambiosCostos = async() => {
    console.log(compraEditar)
    if(costoUnitEditar !=='' && costoEnvioEditar!=='' && costoTotalEditar!=='') {
        var horarioActualizacion = await axios.post(URL_UPDATE_COSTOS,
        {compraEditar,token,costoUnitEditar,costoEnvioEditar,costoTotalEditar})
        .then((resp)=>{
          if(resp.status===200){
              showModalExito.current();
              handleClose();
          }
        })
        .catch((error)=>{
            showModalError.current();
            handleClose();
        });
        
    }
}; 

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title" className="mb-3">
          {titulo}
        </DialogTitle>
        <DialogContent className="pt-3">
          <FormControl sx={{ width: 199 }} size='small'>
            <label htmlFor="inputCostoUnit" className="form-label">Costo Unitario</label>
            <input type="number" className="form-control" id='inputCostoUnit' onChange={(event)=>setCostoUnitEditar(event.target.value)} required />   
            <br/>
            <label htmlFor="inputCostoEnvio" className="form-label">Costo Envio</label>
            <input type="number" className="form-control" id='inputCostoEnvio' onChange={(event)=>setCostoEnvioEditar(event.target.value)} required />
            <br/>
            <label htmlFor="inputCostoTotal" className="form-label">Costo Total</label>
            <input type="number" className="form-control" id='inputCostoTotal' onChange={(event)=>setCostoTotalEditar(event.target.value)} />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" autoFocus
            onClick={()=> onClickGuardarCambiosCostos()}
          >
            Ingresar
          </Button>
          <Button variant="contained" color="error"  autoFocus
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODALES */}
      <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Ingreso de producto exitoso!'} />
      <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error al ingresar producto a stock!'} />
    </div>
  );
}

export default (ModalActCostos);
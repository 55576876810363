import React, { useState, useEffect,useRef } from "react";
import { connect } from "react-redux";
import actions from "../../../actions";
import axios from "axios";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material";
import ModalExito from "../../Modales/ModalExito";
import ModalError from "../../Modales/ModalError";
import { URL_DESCRIPCIONES_OC } from "../../../config";

function ModalIngresoStock({
  showModal,titulo,mensaje,
  ModalIngresoStock,dispatchAddProductToStock,
  addProductToStock
}) {

  /* Activadores modales de exito o error */
  const showModalExito = useRef(null);
  const showModalError = useRef(null);

  /* state variables formulario */
  let [numeroParteProveedorProducto, setNumeroParteProveedorProducto] = useState("");
  let [codigoDessOC, setCodigoDess] = useState("");
  let [descripcionProducto, setDescripcionProducto] = useState("");
  let [cantidadProducto, setCantidadProducto] = useState("");
  let [proveedorProducto, setProveedorProducto] = useState("");
  let [descripcionesForm, setDescripcionesForm] = useState([]);
  /****/

  /*LOGICA DE MODAL */
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    showModal.current = handleClickOpen
  }, [showModal])  
  /****/

  useEffect(() => {
    (async () => {
      //get Carrocerias
      var response = await axios.post(URL_DESCRIPCIONES_OC, { codigoDessOC });
      //let responseBody = await response.json();
      //console.log(response.data)
      setDescripcionesForm(response.data);
      setDescripcionesForm(response.data);
      setDescripcionesForm(response.data);
    })();
  }, [codigoDessOC]);
  
  const saveProducto = () => {
    //Generate instance of Product to save
    const productoPorAgregar = {
      producto : {
        fecha_ingreso: '',
        num_parte: numeroParteProveedorProducto,
        codigo_dess: codigoDessOC,
        descripcion: descripcionProducto,
        cantidad: cantidadProducto,
        proveedor: proveedorProducto
      }
    };
    dispatchAddProductToStock(productoPorAgregar);
    setNumeroParteProveedorProducto('');
    setCodigoDess('');
    setDescripcionProducto('');
    setCantidadProducto('');
    setProveedorProducto('');
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
    handleClose();
    if(addProductToStock.loading === false && addProductToStock.error ===''){
      showModalExito.current();
    }else{
      showModalError.current();
    }
  };


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title">
          {titulo}
        </DialogTitle>
        <DialogContent>
          <div className="row row-cols-4 mt-3">
            <div className="col mb-3">
              <TextField id="outlined-basic" label="Num Parte Proveedor" variant="outlined" 
                onChange={(event) =>
                  setNumeroParteProveedorProducto(event.target.value)
                }
              />
            </div>
            <div className="col mb-3">
              <TextField id="outlined-basic" label="Codigo DESS" variant="outlined" 
                onChange={(event) =>
                  setCodigoDess(event.target.value)
                }
              />
            </div>
            <div className="col mb-3">
              <label  className="form-label">Descripcion</label>
              <input
                type="search"
                list="datalistOptions"
                className="form-control"
                onChange={(event) =>
                  setDescripcionProducto(
                    event.target.value.toLocaleUpperCase()
                  )
                }
                required
              />
              <datalist id="datalistOptions">
                {descripcionesForm.map((elem, index) => {
                  return <option key={index} value={elem.parte} />;
                })}
              </datalist>
            </div>
            <div className="col mb-3">
              <TextField id="outlined-basic" label="Cantidad" variant="outlined" 
                onChange={(event) =>
                  setCantidadProducto(event.target.value)
                }
              />
            </div>
            <div className="col mb-3">
              <TextField id="outlined-basic" label="Proveedor" variant="outlined" 
                onChange={(event) =>
                  setProveedorProducto(event.target.value)
                }
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={saveProducto} autoFocus>
            Ingresar
          </Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODALES */}
      <ModalExito showModal={showModalExito} titulo={'Accion realizada con exito!'} mensaje={'Ingreso de producto exitoso!'} />
      <ModalError showModal={showModalError} titulo={'No se pudo realizar acción!'} mensaje={'Error al ingresar producto a stock!'} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  addProductToStock: state.addProductToStock,
});

const mapDispatchToProps = {
  dispatchAddProductToStock: actions.postProductoStock,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalIngresoStock);
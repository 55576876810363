import React, { useState } from 'react';
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane
  } from 'mdb-react-ui-kit';
import IngresoPiezas from './IngresoPiezas';
import AccionesDesarme from './AccionesDesarme';
import ItemsDesarme from './Inventario/ItemsDesarme';

export default function WizardDesarme({token}) {
    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
        return;
        }

        setBasicActive(value);
    };
    
    return (
        <div >
            <div className='container shadow' style={{marginTop:'20px', backgroundColor:'white'}}>
                <div className='container'>
                    <MDBTabs className='mb-3'>
                    <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                        Ingreso de Piezas Desarme
                    </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'} >
                        Gestion de Piezas Desarme
                    </MDBTabsLink>
                    </MDBTabsItem>
                    {token.data[0].permisos=='1'?void 0:
                    <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                        Registro de acciones en Desarme
                    </MDBTabsLink>
                    </MDBTabsItem>
                    }
                    </MDBTabs>
                    
                    <MDBTabsContent>
                        <MDBTabsPane show={basicActive === 'tab1'}> <IngresoPiezas token={token}/> </MDBTabsPane>
                        <MDBTabsPane show={basicActive === 'tab2'}> <ItemsDesarme token={token}/> </MDBTabsPane>
                        <MDBTabsPane show={basicActive === 'tab3'}> <AccionesDesarme /> </MDBTabsPane>
                    </MDBTabsContent>
                </div>
            </div>
        </div>
    );
}
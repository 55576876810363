import axios from 'axios';
import {API_HOST_PY} from "../../config";

class TicketsService {

  async getTickets() {
    try {
      const response = await axios.get(`${API_HOST_PY}/support/tickets/`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async postTicket(ticketDto) {
    try {
      const response = await axios.post(`${API_HOST_PY}/support/tickets/save`, ticketDto);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async putTicket(ticketDto) {
    try {
      const response = await axios.put(`${API_HOST_PY}/support/tickets/edit`, ticketDto);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new TicketsService();
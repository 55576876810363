import { Button, LinearProgress, TextField } from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import actions from "../../actions";
import ModalEditarStock from "./Modales/ModalEditarStock";
import axios from "axios";
import ReplayIcon from '@mui/icons-material/Replay';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import { URL_POST_EDITAR_STOCK } from "../../config";
import {hasRoleAdmin} from "../../core/auth/auth";

function computeMutation(newRow, oldRow) {
  if (newRow.cantidad !== oldRow.cantidad) {
    return `cantidad de '${oldRow.cantidad}' a '${newRow.cantidad}'`;
  }
  if (newRow.bodega !== oldRow.bodega) {
    return `bodega de '${oldRow.bodega}' a '${newRow.bodega}'`;
  }
  if (newRow.rack !== oldRow.rack) {
    return `rack de '${oldRow.rack}' a '${newRow.rack}'`;
  }
  if (newRow.fila !== oldRow.fila) {
    return `fila de '${oldRow.fila}' a '${newRow.fila}'`;
  }
  if (newRow.caja !== oldRow.caja) {
    return `caja de '${oldRow.caja}' a '${newRow.caja}'`;
  }
  if (newRow.marca_repuesto !== oldRow.marca_repuesto) {
    return `marca_repuesto de '${oldRow.marca_repuesto}' a '${newRow.marca_repuesto}'`;
  }
  return null;
}

function VerStock({
    token,
    stock,
    dispatchFetchStock,
    lemans,imports
}) {

  const bodegasSelect = [
    'BODEGA AUTOFIX',
    'BODEGA ACOPIO',
    'BODEGA 24LEMANS',
  ];
  
  const racksSelect = [
    "R1",
    "R2",
    "R3",
    "R4",
    "R5",
    "R6",
    "R7",
    "R8",
    "R9",
    "R10",
    "R11",
    "R12",
    "R13",
    "R14",
    "R15",
  ];
  
  const filasSelect = [
    "F1",
    "F2",
    "F3",
    "F4",
    "F5",
  ];
  
  const cajasSelect = [
    "SIN CAJA",
    "CAJA 1",
    "CAJA 2",
    "CAJA 3",
    "CAJA 4",
    "CAJA 5",
  ];
      /*data grid */
const columns = [
    {
      field: 'codigo_dess',
      headerName: 'Codigo DESS',
      width: 150,
    },
    {
      field: 'descripcion',
      headerName: 'Descripcion',
      sortable: true,
      width: 320
    },
    {
      field: 'marca_repuesto',
      headerName: 'Marca Producto',
      sortable: false,
      width: 150,
      editable:true,
      type: 'string',
    },
    {
      field: 'cantidad',
      headerName: 'Cantidad Recibida',
      sortable: true,
      width: 100,
      type: 'number',
      editable: hasRoleAdmin(token)
    },
    {
      field: 'bodega',
      headerName: 'Bodega',
      sortable: true,
      width: 180,
      editable:hasRoleAdmin(token),
      type: 'singleSelect',
      valueOptions: bodegasSelect,
    },
    {
      field: 'rack',
      headerName: 'Rack',
      sortable: true,
      width: 80,
      editable:hasRoleAdmin(token),
      type: 'singleSelect',
      valueOptions: racksSelect,
    },
    {
      field: 'fila',
      headerName: 'Fila',
      sortable: true,
      width: 80,
      editable:hasRoleAdmin(token),
      type: 'singleSelect',
      valueOptions: filasSelect,
    },
    {
      field: 'caja',
      headerName: 'Caja',
      sortable: true,
      width: 80,
      editable:hasRoleAdmin(token),
      type: 'singleSelect',
      valueOptions: cajasSelect,
    },
    {
        field: 'fecha_ingreso',
        headerName: 'Fecha Ingreso',
        sortable: true,
        width: 130
    },
    {
      field: 'codigo_invoice',
      headerName: 'Codigo Invoice',
      sortable: true,
      width: 130
    },
  ];

    /*state*/
    let [stockState, setStockState] = useState([]);
    let [textoBuscado,setTextoBuscado] = useState('');
    let [promiseArguments, setPromiseArguments] = useState(null);
    let noButtonRef = useRef(null);
    let [snackbar, setSnackbar] = useState(null);
    let handleCloseSnackbar = () => setSnackbar(null);



    //----------- paginacion de tabla ----------------//
    let [pageSize,setPageSize] = useState(5);

    const showModalEditarStock = useRef(null);

    /* Pop-up cambio en campo tabla */

    const handleEntered = () => {
      // The `autoFocus` is not used because, if used, the same Enter that saves
      // the cell triggers "No". Instead, we manually focus the "No" button once
      // the dialog is fully open.
      // noButtonRef.current?.focus();
    }; 
    
    const processRowUpdate = useCallback(
      (newRow, oldRow) =>
      //console.log(newRow, oldRow)
         new Promise((resolve, reject) => {
          const mutation = computeMutation(newRow, oldRow);
          if (mutation) {
            // Save the arguments to resolve or reject the promise later
            setPromiseArguments({ resolve, reject, newRow, oldRow });
          } else {
            resolve(oldRow); // Nothing was changed
          }
        }),
      [],  
    );

    const handleNo = () => {
      const { oldRow, resolve } = promiseArguments;
      resolve(oldRow); // Resolve with the old row to not update the internal state
      setPromiseArguments(null);
    };
  
    const handleYes = async () => {
      const { newRow, oldRow, reject, resolve } = promiseArguments;
  
      try {
        // Make the HTTP request to save in the backend
        //const response = await mutateRow(newRow);
        await axios.post(URL_POST_EDITAR_STOCK,
          {newRow,token,oldRow})
          .then((resp)=>{
            if(resp.status===200){      
              setSnackbar({ children: 'Cantidad cambiada con éxito', severity: 'success' });
              resolve(newRow);
              setPromiseArguments(null);
              //window.location.reload(false)
              //dispatchFetchStock()
            }
        })
      } catch (error) {
        setSnackbar({ children: "No se pudo realizar la petición", severity: 'error' });
        reject(oldRow);
        setPromiseArguments(null);
        //window.location.reload(false)
      }
    };

    const renderConfirmDialog = () => {
      if (!promiseArguments) {
        return null;
      }
  
      const { newRow, oldRow } = promiseArguments;
      const mutation = computeMutation(newRow, oldRow);
  
      return (
        <Dialog
          maxWidth="xs"
          TransitionProps={{ onEntered: handleEntered }}
          open={!!promiseArguments}
        >
          <DialogTitle>¿Esta seguro?</DialogTitle>
          <DialogContent dividers>
            {`Usted al presionar en 'Si' cambiará la ${mutation}.`}
          </DialogContent>
          <DialogActions>
            <Button ref={noButtonRef} onClick={handleNo}>
              No
            </Button>
            <Button onClick={handleYes}>Yes</Button>
          </DialogActions>
        </Dialog>
      );
    };

    /* Filtro flexible */
    let stockFiltrado = stock.data?.filter(producto => {
        var textoTotal = JSON.stringify(producto)
        var count = -1
        for(let i=0; i<textoBuscado.split(' ').length;i++){
            if(textoTotal.indexOf(textoBuscado.toUpperCase().split(' ')[i]) >= 0){
                count ++;
            }
        }   
        if(count >= 0 && count===textoBuscado.split(' ').length-1){
            return true
        }else {
            return false
        }
    });

    let stockFiltroBodega0 = stockFiltrado.filter(elem => 
      imports?
      true:
      elem.bodega? true : false
    );

    let stockFiltroBodega = stockFiltroBodega0.filter(elem => 
      lemans?
      elem.bodega.indexOf('BODEGA 24LEMANS') >= 0
      :
      imports?
      elem.tienda.indexOf('IMPORT') >= 0
      :
      elem.bodega.indexOf('BODEGA AUTOFIX') >= 0 || elem.bodega.indexOf('BODEGA ACOPIO') >= 0
    );

    //Funcion de paginacion
    useEffect(() => {
      setStockState(stockFiltroBodega.map((elem,index)=>({...elem,id:index})))
    }, [textoBuscado,stock.loading, stockFiltroBodega])
    

    useEffect(() => {
        dispatchFetchStock();
    }, [dispatchFetchStock]);

  return (
    <React.Fragment>
        <div className="row">
            <div className="col-3">
                <TextField className="mb-3" id="outlined-basic" label="Buscador" variant="outlined" 
                    onChange= {(event) => {setTextoBuscado(event.target.value)}} 
                />
            </div>
            <div className="col">
                <Button variant="outlined" color="primary" onClick={()=>{dispatchFetchStock()}} >
                    Actualizar <ReplayIcon />
                </Button>
            </div>
        </div>

        <div style={{ height: 500, width: '100%' }}>
        {stock.loading && <LinearProgress color="success" />}
        {renderConfirmDialog()}
        <DataGrid
          rows={stockState}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10,20,50]}
          disableSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          />

          {!!snackbar && (
                  <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                  </Snackbar>
                )}

      </div>

    <ModalEditarStock showModal={showModalEditarStock} token={token} titulo={'Editar Stock'}/>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  stock: state.stock,
});

const mapDispatchToProps = {
  dispatchFetchStock: actions.fetchStock
};

export default connect(mapStateToProps, mapDispatchToProps)(VerStock);
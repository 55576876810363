import React, { useState } from "react";
import IngresoUbStock from "./IngresoUbStock";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import VerStock from "./VerStock";

export default function Stock({ token, lemans,imports }) {
  const [basicActive, setBasicActive] = useState(!imports?"tab1":"tab2");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  return (
    <div className="col">
      <h3 className="container">{lemans?'STOCK 24LEMANS':imports?'STOCK IMPORTACIONES':'STOCK AUTOFIX'}</h3>
      <div
        className="container shadow"
        style={{ marginTop: "20px", backgroundColor: "white" }}
      >
        <div className="container">
          <MDBTabs className="mb-3">
            {!imports&&
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab1")}
                  active={basicActive === "tab1"}
                >
                  Ingresar Ubicación de Stock
                </MDBTabsLink>
              </MDBTabsItem>
            }
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab2")}
                active={basicActive === "tab2"}
              >
                Ver Stock
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent>
            <MDBTabsPane show={basicActive === "tab1"}>
            {" "}
            <IngresoUbStock token={token} lemans={lemans}/>{" "}
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === "tab2"}>
              {" "}
              <VerStock token={token} lemans={lemans} imports={imports}/>{" "}
            </MDBTabsPane>
          </MDBTabsContent>
        </div>
      </div>
    </div>
  );
}
